/* App Frame */ 

#frame {
    > b {
        background: $frameColor;
        position: fixed;
        z-index: z('frame');
    }
    #left, #right {
        top: 0; bottom: 0;
        width: $maxWidthFrame;
        @include media-query('xsmax') {
            width: $xsWidthFrame;
        }
    }
    #left { left: 0; }
    #right { right: 0; }
            
    #top, #bottom {
        left: 0; right: 0;
        height: $maxWidthFrame;
        @include media-query('xsmax') {
            height: $xsWidthFrame;
        }
    }
    #top { top: 0; }
    #bottom { bottom: 0; }   
}