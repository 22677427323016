/*
* BOARD INTRO Header 
* similar to board gallery one "SPLIT" layout
*
* Used on App AND on Biosingel (page) layout!!
* Textposition always right (RTL = left)
*/
// TEXT RIGHT 
// .board-intro[data-variant="intro"] {
.board-intro {
    grid-auto-rows: unset; // reset rows
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);

    .board-figure-1 {
        grid-column: span 12; 
        grid-row: 1;
    }
    .board-content {
        grid-column: span 12; 
        grid-row: 2;
    } 
    
    .board-header {
        margin-bottom: $space-m;
        margin-bottom: $space-m-fluid;
    }

    .board-footer {
        .text-board[data-variant=authors] {
            @include media-query('xsmax') {
                font-size: 0.75rem;
            }
        }
    }

    // on larger devices make it flex
    @include media-query('boardmin') { 
        // grid-template-rows: $mobileHeaderHeight 1fr minmax(min-content, 500px) 1fr $mobileHeaderHeight; // 5 rows spacer 1f minmax(for image) 1fr spacer
        // grid-template-rows: minmax(33vh, calc(100vh - 240px)); // 1 row max = $mobileHeaderHeight * 2 + 60px
        grid-template-rows: minmax(33vh, 73vh); // 1 row max similar to gallery one where the max is 67vh + 3vh +3vh = 73 vh max
        grid-template-columns: 1fr calc(100% / 12) 30% ; // 3 cols: 30% content 100%12 (overlapping zone 12grid) 1f image

        .board-figure-1 {
            grid-column: 1 / 3; 
            // grid-row: 3 / 4;
            grid-row: 1;
        }
        .board-content {
            grid-column: 3 / 4; 
            // grid-row: 3 / 4;
            grid-row: 1;

            display: flex;
            // height: 100%; 
            flex-direction: column;
            // align-items: center;
            justify-content: space-around;

            padding-left: $boardHorizontalSpaceMin; // FALLBACK
            padding-left: $boardHorizontalSpaceFluid; // CLAMP
            padding-bottom: 0;
        }
        .board-header {
            margin-bottom: 0;
        }

        /* RTL */
        &[data-dir="rtl"] {
            .board-content {
                padding-left: 0;
                padding-right: $boardHorizontalSpaceMin; // FALLBACK
                padding-right: $boardHorizontalSpaceFluid; // CLAMP
            }
        }
    }

    /* Exception  
    * Portrait Imagas --> Crop Mode == false
    * See also board gallery one (split) -> we do the same there (limit the max width to better accomondate Portrait Images)
    */
    &[data-image-crop="false"] {
        @include media-query('boardmin') { 
            width: 100%;
            max-width: 1366px; // limit the width of the grid container to position it centered
            
            // place-self: center;
            align-self: center;
            grid-gap: 0; // 
        }
    }
}

/* Exception on Biosingle */

// on smaller devices setup grid to reorder elements of board content
.board-intro[data-variant="biosingle"] {
  
    // max board
    @include media-query('noboardmax') { 
        .board-content {
            display: grid;
            
            // make it apear like a paragraph 
            padding-bottom: $space-m;
            padding-bottom: $space-m-fluid;
        }
        .board-header {
            grid-row: 1; 
        }
        .board-footer {
            grid-row: 2; 
        }
        .board-introtext {
            grid-row: 3; 
        }
    }
}