/* Note: 
* Containers with .--hidden-Modifier are containers within media elements (figures) 
* -> only the Video Containter within Modals has no .--hidden class, s
* -> ince we replace the iframe of the  --hidden class within media items (figures with placholder images), but not in modals. 
*/


.video-container {
    position: relative;
    overflow: hidden;

    // JS - youtube.js sets this
    // &[data-playing="false"] {}
    // &[data-playing="true"] {}
}

/* :not(.--hidden) -> its the Modal Video Container! */ 
.video-container-modal {
    --w: 16;
    --h: 9;
    display: block;
    padding-bottom: calc(100% / var(--w) * var(--h));
    background: var(--color-black);
    height: 0;
    width: 100%; 
}

/* The Inline Container*/
.video-container-inline {
    display: none;
    z-index: -1;

    // JS - youtube.js sets this
    &[data-playing="true"] {
        display: block; 
        z-index: 1;
    }
}
  
/* Note: this targets all video-containers, not just modals */
.video-container iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // object-fit: cover;
    border: 0;
}