/*
* 2 Split Screen
* FINISHED
* BASE 
*/ 
.board-gallery-one[data-variant="split"]  {
    grid-auto-rows: unset; // reset rows
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-columns: 100%;

    .board-figure-1 {
        grid-column: 1; 
        grid-row: 1;
    }
    .board-content {
        grid-column: 1; 
        grid-row: 2;
    } 
    @include media-query('noboardmax') { 
        .board-content .text-board {
            max-width: initial !important;
            width: 100%;
            columns:  320px 2;
            column-gap: 3rem;
        }
    }

    // Base Split (for all gallery ones including overlap mode)
    @include media-query('boardmin') { 
        grid-template-rows: 3vh minmax(50vh, calc(100vh - 33vh)) 3vh;
        // grid columns see indivdiual Modes below!!

        .board-figure-1 {
            grid-column: 2 / 4; 
            grid-row: 2 / 3;
        }
        .board-content {
            grid-column: 1 / 2; 
            grid-row: 1 / 4;

            display: flex;
            // height: 100%; 
            flex-direction: column;
            // align-items: center;
            justify-content: center;
        }
    }
    @include media-query('boardbetween') { 
        .board-figure-1 {
            grid-row: 1 / 4;
        }
    }
    /* height exception */
    // 1216 = boardmin = xl and MAX height 768
    @media only screen and (max-height: 768px) and (min-width: 1216px) { 
        .board-content {
            justify-content: flex-start;
        }
        .board-content .text-board {
            padding-bottom: $boardContentBottomSpacer; 
        }
        .board-figure-1 {
            grid-row: 1 / 4;
        }
    }
}

/*
* MODE A - Split
* Textposition (NO OVERLAPPING TEXT - grid-col + space adjust LEFT vs RIGHT
*/
// TEXT RIGHT 
.board-gallery-one[data-variant="split"][data-text-position="right"] {
    @include media-query('boardmin') { 
        grid-template-columns: 1fr calc(100% / 12) 30%;

        .board-figure-1 {
            grid-column: 1 / 3; 
        }
        .board-content {
            grid-column: 3 / 4; 
            padding-left: $boardHorizontalSpaceMin; // FALLBACK
            padding-left: $boardHorizontalSpaceFluid; // CLAMP
        }
    }
    @include media-query('boardbetween') { 
        grid-template-columns: 1fr calc(100% / 12) 25%;
    }
    /* height exception */
    @media only screen and (max-height: 768px) and (min-width: 1216px) { 
        grid-template-columns: 1fr calc(100% / 12) 33%;
    }
}
// TEXT LEFT 
.board-gallery-one[data-variant="split"][data-text-position="left"] {
    @include media-query('boardmin') { 
        grid-template-columns: 30% calc(100% / 12) 1fr;
        
        .board-figure-1 {
            grid-column: 2 / 4; 
        }
        .board-content {
            grid-column: 1 / 2; 
            padding-right: $boardHorizontalSpaceMin; // FALLBACK
            padding-right: $boardHorizontalSpaceFluid; // CLAMP
        }
    }
    @include media-query('boardbetween') { 
        grid-template-columns: 25% calc(100% / 12) 1fr;
    }
    /* height exception */
    @media only screen and (max-height: 768px) and (min-width: 1216px) { 
        grid-template-columns: 33% calc(100% / 12) 1fr;
    }
}


/*
* MODE B - Split + Overlay Overrides
* Overlap Active
* NOTE: Rows could me merges in both variants == they are the same, only cols shift around
*/
// TEXT RIGHT W OVERLAP
.board-gallery-one[data-variant="split"][data-text-overlap="true"][data-text-position="right"] {
    @include media-query('boardbetween') { 
        grid-template-columns: 1fr calc(100% / 8) 20%;

        .board-figure-1 {
            grid-column: 1/3;
            grid-row: 1/4;
        }
        .board-content {
            grid-column: 2/4;
            grid-row: 2/3;
            // padding-bottom: 0;
        }
        .text-board {
            background-color: rgba(243,243,243,0.92);
            padding: $boardHorizontalSpaceMin 0 $boardHorizontalSpaceMin $boardHorizontalSpaceMin; // FALLBACK
            padding: $boardHorizontalSpaceFluid 0 $boardHorizontalSpaceFluid $boardHorizontalSpaceFluid; // CLAMP
        }
    }
}
// TEXT LEFT W OVERLAP
.board-gallery-one[data-variant="split"][data-text-overlap="true"][data-text-position="left"] {
    @include media-query('boardbetween') { 
        grid-template-columns: 20% calc(100% / 8) 1fr;

        .board-figure-1 {
            grid-column: 2/4;
            grid-row: 1/4;
        }
        .board-content {
            grid-column: 1/3;
            grid-row: 2/3;
            // padding-bottom: 0;
        }
        .text-board {
            background-color: rgba(243,243,243,0.92);
            padding: $boardHorizontalSpaceMin $boardHorizontalSpaceMin $boardHorizontalSpaceMin 0; // FALLBACK
            padding: $boardHorizontalSpaceFluid $boardHorizontalSpaceFluid $boardHorizontalSpaceFluid 0; // CLAMP
        }
    }
}


/* Exceptions */
// Set up different grid cols if [data-image-crop="false"]
// In this case, we have a portrait and do want to set it with the least cropping, but still fit height
.board-gallery-one[data-variant="split"][data-image-crop="false"] {
    @include media-query('boardmin') { 
        width: 100%;
        max-width: 1366px; // limit the width of the grid container to position it centered
        
        // place-self: center;
        align-self: center;
        grid-gap: 0; // 
    }

    // Split mode
    &[data-text-position="right"] {
        @include media-query('boardmin') { 
            grid-template-columns: 60% 0% 40%;
            
        }
    
        /* height exception */
        @media only screen and (max-height: 768px) and (min-width: 1216px) { 
            max-width: 1024px;
            grid-template-columns: 33% 0% 66%;

            .board-content {
                justify-content: center;
            }
        }
    }
    &[data-text-position="left"] {
        @include media-query('boardmin') { 
            grid-template-columns: 40% 0% 60%;
            
        }
    
        /* height exception */
        @media only screen and (max-height: 768px) and (min-width: 1216px) { 
            max-width: 1024px;
            grid-template-columns: 66% 0% 33%;

            .board-content {
                justify-content: center;
            }
        }
    }

    // overlap mode - dont overlap at all if crop mode is false
    // just reset the original overlap values (padding / spacing and background)
    &[data-text-overlap="true"] {
        &[data-text-position="right"],
        &[data-text-position="left"] {
            .board-content .text-board {
                background: none transparent; 
                padding: 0; 

                @media only screen and (max-height: 768px) and (min-width: 1216px) { 
                    padding: 0 0 $boardContentBottomSpacer 0; 
                }
            }
        }
        // &[data-text-position="right"] {}
        // &[data-text-position="left"] { }
    }
}


/*
* RTL HANDLING
* NOTE: you could set the following directly on the container, to always be ltr 
*/
// lets fix RTL 
// since we want the container to be set by panel user 
// we need to change the behavior of browsers 
// either by setting direction manually on grid container OR 
// by using named lines (the latter we do not have, we'd have to refactor the whole css above)
.board-gallery-one[data-dir="rtl"] {
    @include media-query('boardmin') { 
        direction: ltr; // dont let browser set rtl 
        .board-content {
            direction: rtl; // let the text be RTL of Course!!!
        }
    }
}
