/* base */
.dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    z-index: 11;

    opacity: 0; 
    transform: translateY(-10px);
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out; 
}
/* test hover/focus pure CSS before JS*/
/*
.dropdown > [data-variant="btn-drop"]:focus + .dropdown-menu {
    opacity: 1; 
    transform: translateY(0px);
}
.dropdown > [data-variant="btn-drop"].btn-icon--caret:focus > .btn-icon-wrap {
    transform: rotate(-90deg);
}
*/

/* JS */
.dropdown.active {
    // the menu 
    > [data-variant="btn-drop"] + .dropdown-menu {
        opacity: 1; 
        transform: translateY(0px);
    }
    // the button caret
    > [data-variant="btn-drop"].btn-icon--caret > .btn-icon-wrap {
        transform: rotate(-90deg);
    }
    /* RTL */
    &[data-dir="rtl"] > [data-variant="btn-drop"].btn-icon--caret > .btn-icon-wrap {
        transform: rotate(180deg);
    }
}

.dropdown-menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.dropdown-menu-link {
    font-size: 17px;
    font-weight: 700;
    display: block;
    min-width: 90px; // min width
    padding: 0.25rem 0;

    transition: color 160ms ease;
    &:hover {
        color: var(--color-turquoise-dark); 
        text-decoration: none;
    }
}

/* variants */
/* languages */
$langSideOffset: 125px; // calc(120px + 0.75rem)

.dropdown-languages {
    // top = mobile
    &[data-variant="top"] {

        @include media-query('lg') {
            display: none;
            pointer-events: none;
        }
    }

    // side = desktop
    &[data-variant="side"] {
        display: none;
        pointer-events: none;
        @include media-query('lg') {
            display: block;
            pointer-events: auto;
        }
    }
}

.dropdown-menu-languages {
   
    background-color: white;
    padding: .75rem;
    border-radius: .1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .1);

    // top = mobile
    &[data-variant="top"] {
        right: 12px; // 0.75rem == 12px margin offset caret
        // RTL
        &[data-dir="rtl"] {
            right: auto;
            left: 0;
        }
    }
    // side = desktop
    &[data-variant="side"] {
        bottom: 0;
        right: calc($langSideOffset * -1);
        
        // RTL
        &[data-dir="rtl"] {
            right: auto;
            left: calc($langSideOffset * -1);
        }
    }
}

/* styles by language LTR - RTL */





