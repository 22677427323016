
.margin-s {
    @include marginS(); 
}
.margin-m {
    @include marginM(); 
}
.margin-l {
    @include marginL(); 
}
.margin-xl {
    @include marginXL(); 
}
.margin-xxl {
    @include marginXXL(); 
}
