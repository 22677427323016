.section-header {
    .text {
        max-width: $maxSectionHeaderWidth;
    }

    .text p,
    .lead {
        font-style: italic;
        opacity: 0.7;
    }
    
    &[data-variant="goto"] {
        width: 100%; 

        .goto-link {
            margin-top: 1rem;
        }

        @include media-query('lg') {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;

            .goto-link {
                margin-top: 0;
            }
        } 
    }
}