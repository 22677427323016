.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: z('nav');
  
    &.open {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }
    &-bg {
      position: absolute;
      background: rgba(0,0,0,0.92);
      width: 100%;
      height: 100%;
    }
    &-container {
      border-radius: 2px;
      background: #fff;
      position: relative;
      padding: 30px;
    }
    &-close {
      position: absolute;
      // right: 15px;
      // top: 15px;
      right: 8px;
      top: 8px;

      &.btn-icon--close {
        .btn-icon-wrap svg {
          inline-size: 1.25rem; 
        }
      }
    }
}

.modal-container {
  &.wrapper {
    width: 100%; 
    max-width: $maxWidthPage;
    background: transparent;
  }
}


/* Exceptions */ 
/* Individual Styles by ID */
/* Modal Boards -> Page Nav of a Single Bioagraphy in App Mode */ 
#modal-boards {
  h1 {
    margin-bottom: 0.75rem;
  }
  .modal-bg {
    background: var(--color-green);
  }

  // .modal-close
  .modal-close {
    position: fixed;
    right: calc($maxWidthFrame * 2); 
    // top: calc($maxWidthFrame * 2); 
    top: calc(100px - 32px); // about the same distance from top as the hamburger
    
    @include media-query('mdmax') {
      top: calc($mobileHeaderHeight + $maxWidthFrame); 
    }

    @include media-query('lg') {
      &.btn-icon--close {
        .btn-icon-wrap svg {
          inline-size: 2rem; 
        }
      }
    }
  }
  // make scrollable
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;

  overflow-x: hidden;
  .modal-container {
    flex: 0 1 auto; 
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto; // this makes it scrollable

    // add min-spaces 
    // padding-top: calc($mobileHeaderHeight + 4rem);
    // padding-bottom: 4rem; 
    padding-top: calc($mobileHeaderHeight * 1.5);
    padding-bottom: calc($maxWidthFrame * 2);
    @include media-query('lg') {
      padding-top: 4rem;
      padding-bottom: 4rem; 
    }
  }
}
/* Exception JS */
/* reposition if header is missing due to scroll down especially on mobile */ 
.scroll-down #modal-boards {
  @include media-query('mdmax') {
    .modal-container {
      padding-top: 1.5rem; 
    }
    .modal-close {
      top: 1.5rem; // calc($mobileHeaderHeight + $maxWidthFrame)
    }
  }
}

/* Modal Youtube */ 
#modal-youtube {
  .modal-container {
    width: 100%; 
    max-width: calc(100% - ($maxWidthFrame * 3));

    @include media-query('mdmax') {
      padding: 15px; 
    }

    @include media-query('lg') {
      width: 66vw;
      max-width: initial;
    }
  }

  .modal-close {
    @include media-query('mdmax') {
      top: -25px;

      .btn-icon-wrap {
        color: var(--color-light);
      }
    }
  }
}

/* RTL */
[dir="rtl"] {
  .modal-close {
    right: auto;
    left: 8px; 
    // @include media-query('xsmax') {}
  }
  /* Exceptions */
  #modal-boards .modal-close {
    right: auto; 
    left: calc($maxWidthFrame * 2); 
  }
}
