.board-copy {
    .board-content {
        padding-bottom: 0; // reset
    }

    @include media-query('boardmin') { 
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;

        margin-left: calc($boardHorizontalSpaceMin * -1);
        margin-left: calc($boardHorizontalSpaceFluid * -1);
        margin-right: calc($boardHorizontalSpaceMin * -1);
        margin-right: calc($boardHorizontalSpaceFluid * -1);
        
        .board-content {
            padding-left: $boardHorizontalSpaceMin;
            padding-left: $boardHorizontalSpaceFluid;
            padding-right: $boardHorizontalSpaceMin;
            padding-right: $boardHorizontalSpaceFluid;

            width: 50%;
        }
    }

    // text formats 
    // if film section 
    ul + h2 {
        margin-top: $space-xl; 
        margin-top: $space-xl-fluid;
    }
}

/* Exceptions */
/* Display Text Sources always left to right (non-translatable content */
.board-copy[data-dir="rtl"] {
    .board-content[data-variant="sources-text"] {
        h2, ul {
            direction: ltr;
            text-align: right;
        }
    }
} 