// .social {}

.social-links {
    display: flex;
    // padding: 0 .5rem;
}
.social-links a {
    // padding: 1rem .5rem;
    padding: 0; 
    height: 24px;
    width: 24px; 
    margin-right: 1rem; 
    line-height: 1;
    color: var(--color-dark); 
    transition: color 200ms ease; 
    svg {
        height: 100%;
        width: 100%; 
        fill: currentColor; 
    }
    &:hover, &:focus {
        color: var(--color-turquoise); 
    
    }
}

.social-title {
    line-height: 1;
    margin-bottom: 0.5rem;
}


/* Variants Hero (home)*/
.social[data-variant="hero"] {
    padding: 1.5rem 0;
    border-top: 1px solid $borderGrayLight;
    border-bottom: 1px solid $borderGrayLight;
    // border-top: 1px solid var(--color-dark);
    // border-bottom: 1px solid var(--color-dark);

    @include media-query('xl') {
        padding: 0.5rem 0;
        border: 0px solid transparent; 
    }

    @include media-query('lgmax') {
        .social-links {
            justify-content: center; 
            a {
                margin: 0 0.5rem; 
            }
        }
        &[data-dir="rtl"] {
            .social-links a {
                margin: 0 0.5rem; 
            }
        }
    }
}

/* Variants MENU */
$socialHoverColor: #22393a; // same as menu see menu.scss
.social[data-variant="menu"] {
    margin-top: $space-m;
    margin-top: $space-m-fluid;

    .social-title,
    .social-links a {
        color:var(--color-light);
    }
    .social-links a {
        &:hover, &:focus {
            color: $socialHoverColor;
        
        }
    }
}


/* RTL */
[data-dir="rtl"] {
    .social-links a {
        margin-right: 0; 
        margin-left: 1rem; 
    }
}
