.bg-light {
  background-color: var(--color-light);
}
.color-grey {
  color: var(--color-text-grey);
}
  
  /*
  .header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: -1rem;
    margin-left: -1rem;
    margin-bottom: 6rem;
  }
  */
  /*
  .logo {
    padding: 1rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
  }
  */
  /*
  .menu {
    display: flex;
  }
  .menu a {
    padding: 1rem;
    display: block;
  }
  .menu a[aria-current] {
    text-decoration: underline;
  }
  
  .social {
    display: flex;
    padding: 0 .5rem;
  }
  .social a {
    padding: 1rem .5rem;
  }
  */
  
  /*
  .section {
    padding: 3rem 0;
  }
  */
  
  .grid {
    --columns: 12;
    --gutter: 3rem;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr;
  }
  /*
  .grid > .column:not(.section-header) {
    margin-bottom: var(--gutter);
  }
  */ 
  .autogrid {
    --gutter: 3rem;
    --min: 10rem;
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(auto-fit, minmax(var(--min), 1fr));
    grid-auto-flow: dense;
  }
     /*
  h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  .text {
    line-height: 1.5em;
  }
  .text a {
    text-decoration: underline;
  }
  .text :first-child {
    margin-top: 0;
  }
  .text :last-child {
    margin-bottom: 0;
  }
  .text p,
  .text ul,
  .text ol {
    // margin-bottom: 1.5rem;
    // margin-bottom: 2rem;
    @include marginM(); 
  }
  .text ul,
  .text ol {
    margin-left: 1rem;
  }
  .text ul p,
  .text ol p {
    margin-bottom: 0;
  }
  .text ul > li {
    list-style: disc;
  }
  .text ol > li {
    list-style: decimal;
  }
  .text ul ol,
  .text ul ul,
  .text ol ul,
  .text ol ol {
    margin-bottom: 0;
  }
 
  .text h1,
  .h1,
  .intro {
    font-size: 2rem;
    margin-bottom: 3rem;
    line-height: 1.25em;
  }
  .text h2,
  .h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.25rem;
  }
  .text h3,
  .h3 {
    font-weight: 600;
  }
  */
  /*
  .text .codeblock {
    display: grid;
  }
  .text code {
    font-family: var(--font-family-mono);
    font-size: 1em;
    background: var(--color-light);
    padding: 0 .5rem;
    display: inline-block;
    color: var(--color-black);
  }
  .text pre {
    margin: 3rem 0;
    background: var(--color-black);
    color: var(--color-white);
    padding: 1.5rem;
    overflow-x: scroll;
    overflow-y: hidden;
    line-height: 1.5rem;
  }
  .text pre code {
    padding: 0;
    background: none;
    color: inherit;
  }
  */

/*
  .text hr {
    margin: 6rem 0;
  }
  .text dt {
    font-weight: 600;
  }
  */
  /*
  .text blockquote {
    font-size: 1.25rem;
    line-height: 1.325em;
    border-left: 2px solid var(--color-black);
    padding-left: 1rem;
    margin: 3rem 0;
    max-width: 25rem;
  }
  .text blockquote footer {
    font-size: .875rem;
    font-style: italic;
  }
  */
  /*
  .text figure {
    margin: 3rem 0;
  }
  .text figcaption {
    padding-top: .75rem;
    color: var(--color-text-grey);
  }
  
  hr {
    border: 0;
    background: currentColor;
    height: 2px;
    width: 1.5rem;
    margin: 3rem auto;
  }
  
  .align-center {
    text-align: center;
  }
  */
  /*
  .intro {
    max-width: 40rem;
  }
  .intro *:not(:last-child) {
    margin-bottom: 1em;
  }
  */
  .cta {
    background: var(--color-black);
    color: var(--color-white);
    display: inline-flex;
    justify-content: center;
    padding: .75rem 1.5rem;
    border: 4px solid var(--color-white);
    outline: 2px solid var(--color-black);
  }
  
  .box {
    background: var(--color-light);
    padding: 1.5rem;
    border: 4px solid var(--color-white);
    outline: 2px solid var(--color-light);
  }
  
  .video,
  .img {
    position: relative;
    display: block;
    --w: 1;
    --h: 1;
    padding-bottom: calc(100% / var(--w) * var(--h));
    background: var(--color-black);
  }
  .img img,
  .video iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
  }
  .img[data-contain] img {
    object-fit: contain;
  }
  .img-caption,
  .video-caption {
    padding-top: .75rem;
    line-height: 1.5em;
  }
  
  /*
  .footer {
    padding: 9rem 0 6rem;
    line-height: 1.5em;
  }
  .footer:before {
    content: "";
    display: block;
    width: 1.5rem;
    height: 2px;
    background: var(--color-black);
    margin-bottom: 1.5rem;
  }
  
  .footer h2 {
    font-weight: 600;
    margin-bottom: .75rem;
  }
  .footer ul,
  .footer p {
    color: var(--color-text-grey);
  }
  .footer p {
    max-width: 15rem;
  }
  .footer a:hover {
    color: var(--color-text);
  }
  */
  
  .map {
    --w: 2;
    --h: 1;
    padding-bottom: calc(100% / var(--w) * var(--h));
    position: relative;
    overflow: hidden;
    background: var(--color-black);
  }
  .map iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  

  
  
  // @media screen and (min-width: 60rem) {
  @include media-query('lg') { 
    body {
      --padding: 3rem;
    }
  
    .grid {
      grid-template-columns: repeat(12, 1fr);
    }
    .grid > .column {
      grid-column: span var(--columns);
    }
  
  }
  
  .pagination {
    display: flex;
    padding-top: 6rem;
  }
  .pagination > span {
    color: var(--color-text-grey);
  }
  .pagination > * {
    padding: .5rem;
    width: 3rem;
    text-align: center;
    border: 2px solid currentColor;
    margin-right: 1.5rem;
  }
  .pagination > a:hover {
    background: var(--color-black);
    color: var(--color-white);
    border-color: var(--color-black);
  }
  
  /*
  .note-excerpt {
    line-height: 1.5em;
  }
  .note-excerpt header {
    margin-bottom: 1.5rem;
  }
  .note-excerpt figure {
    margin-bottom: .5rem;
  }
  .note-excerpt-title {
    font-weight: 600;
  }
  .note-excerpt-date {
    color: var(--color-text-grey);
  }
  */


  // BASIC LIGHTBOX OVERRIDE
  .body-page, .body-app {
    .basicLightbox {
      z-index: 3; 
    }
  }




/*
div.youtube .title, div.youtube .external {
  font-family: Arial, Helvetiva, sans-serif;
  text-align: center;
}
div.youtube .title {
  position: absolute;
  top: 15px;
  width: 100%;
  color: #fff;
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
}
div.youtube .external {
  position: absolute;
  top: 30%;
  width: 100%;
  color: red;
  text-shadow: 1px 1px 2px white, 0 0 1em white, 0 0 0.2em white;
}
div.youtube .play {
  background: #333;
  border-radius: 50% / 10%;
  color: #fff;
  font-size: 2em;
  height: 1.5em;
  width: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
div.youtube .play:hover {
  background: red;
}
div.youtube .play::before {
  background: inherit;
  border-radius: 5% / 50%;
  bottom: 9%;
  content: "";
  left: -5%;
  position: absolute;
  right: -5%;
  top: 9%;
}
div.youtube .play::after {
  border-style: solid;
  border-width: 1em 0 1em 1.732em;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
  content: " ";
  font-size: 0.35em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
}
.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
}
.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
}
.popup-close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
.popup-close::before {
  display: inline-block;
  content: "\00d7";
  color: #fff;
  font-size: 42px;
  font-weight: bold;
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
  cursor: pointer;
}

*/
  