/*
* Global Vars 
*/ 
// Header
$mobileHeaderHeight: 80px; 
// Frame
$maxWidthFrame: 1rem; // 0.5 on xs
$xsWidthFrame: 0.5rem;
$frameColor: white; 
// Max regular Page width
$maxWidthPage: 100rem; // 1600px
$maxWidthPageSingle: 74rem; // 1184px

// Boards
// Max Board width
$maxWidthBoard: 118.75rem; // 1900px
// Board spacers 
$board-min-space: 1.5rem; // scaffolding
// vertical spacers (margins etc)
$boardVerticalSpaceMin: $board-min-space;
$boardVerticalSpaceFluid: clamp(calc($board-min-space * 2), 1.2rem + 1.5vw, 6rem);
// horizontal spacers (paddings)
$boardHorizontalSpaceMin: $board-min-space; 
$boardHorizontalSpaceFluid: clamp($board-min-space, 1.2rem + 1.5vw, 3rem);
// board content spacer (mostly bottom - applied differently according to breakpoints) - needed to scroll if board content extends prefered height
$boardContentBottomSpacer: calc($maxWidthFrame * 6); 

// Sections
$maxSectionHeaderWidth: 600px; // 992 / 3 * 2

// Fluid Spacing 
$space-s: 0.625rem;
$space-s-fluid: clamp(0.625rem,0.58rem + 0.225vw,0.85rem);

$space-m: 1.5rem;
$space-m-fluid: clamp(1.5rem, 1.4rem + 0.5vw, 2rem);

$space-l: 3rem;
$space-l-fluid: clamp(3rem, 2.7rem + 1.5vw, 4.5rem);

$space-xl: 4.5rem;
$space-xl-fluid: clamp(4.5rem, 4.2rem + 1.5vw, 6rem);

$space-xxl: 6rem;
$space-xxl-fluid:  clamp(6rem, 4.4rem + 8vw, 14rem);



// other colors
$borderGray: rgba(23,23,23,0.33);
$borderGrayLight: rgba(23,23,23,0.12);
$softOpacity: 0.4;