// Vars ---------------------------------------------------------------- //
$basicLightbox__background: rgba(0, 0, 0, .8) !default;
$basicLightbox__zIndex: 1000 !default;
$basicLightbox__duration: .4s !default;
$basicLightbox__timing: ease !default;

// basicLightbox ------------------------------------------------------- //
.basicLightbox {

	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: $basicLightbox__background;
	opacity: .01; // Start with .01 to avoid the repaint that happens from 0 to .01
	transition: opacity $basicLightbox__duration $basicLightbox__timing;
	z-index: $basicLightbox__zIndex;
	will-change: opacity;

	&--visible {
		opacity: 1;
	}

	&__placeholder {
		max-width: 100%;
		transform: scale(.9);
		transition: transform $basicLightbox__duration $basicLightbox__timing;
		z-index: 1;
		will-change: transform;


		> img:first-child:last-child,
		> video:first-child:last-child,
		> iframe:first-child:last-child {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			max-width: 95%;
			max-height: 95%;
		}

		> video:first-child:last-child,
		> iframe:first-child:last-child {
			pointer-events: auto;
		}


		> img:first-child:last-child,
		> video:first-child:last-child {
			width: auto;
			height: auto;
		}  
	}

	&--img &__placeholder,
	&--video &__placeholder,
	&--iframe &__placeholder {
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&--visible &__placeholder {
		transform: scale(1);
	}

}

/* Custom Lightbox on app pages (with captions etc) */
.body-app {
    .customLightbox {
        .basicLightbox__placeholder {
            pointer-events: none;
            width: 100%;
		    height: 100%;
        }
        &-modal {
            display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			max-width: 90%;
			max-height: 85%;

            display: flex;
            flex-direction: column;
            justify-content: center; 
            align-items: center;
        }

        .figure {            
            position: relative;
            max-height: 100%;
        }
        .figure img {
            width: auto;
            height: auto; 
            max-height: 100%;
            
        }

        .figcaption {
            position: relative;
            width: 100%;
            background-color: var(--color-gray-light);
            padding: 0.25rem 0.5rem; 
            
            @include media-query('md') {
                position: absolute;
                top: auto;
                bottom: 0;
                left: 0;
                right: 0;
            }
            
        }
        .figcaption .copy-sign {
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        .figcaption svg {
            flex: 0 1 auto;
            align-self: center;
            height: auto;
            max-height: 100%; 
            width: 12px; 
        }
    }
}

