/* ul.credits */ 

.credits {
    padding: 0;
    margin: 0; 

    @include media-query('xl') {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;

    } 
}

.credit {
    list-style-type: none;
    align-self: start;

    display: grid;
    grid-template: [credit-start] "person" [credit-end]
                    [cfooter-start] "loc" [cfooter-end] /
                    1fr;
    
    .person {
        grid-area: person; 
    }
    .location {
        grid-area: loc;
    }
    /*
    .location-link {
        grid-area: link; 
        font-style: normal;
        // @include captiontext(); 
    }
    */

    /* formats */
    .location {
        @include captiontext(); 
        max-width: 40ch;
    }

}

[data-template="credits"] {
    .content-widget {
        .text:not(:first-child) {
            margin-top: $space-xl;
            margin-top: $space-xl-fluid;
        }
    }
}
