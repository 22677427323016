/*
* this is only some rough setup
* Sets the default starting point for all galleries
* Note that this gets highly overriden by the individual boards layout
* 
*/ 

.gallery-grid {
    display:grid;
    // grid-template-columns: repeat(6,1fr);
    grid-template-columns: repeat(12,1fr);
    // grid-auto-rows:100px 300px;
    grid-auto-rows: 1fr; // egqual rows
    grid-gap: 0.625rem; // 0.625rem 10px
    grid-auto-flow: dense;

    @include media-query('noboardmax') {
        grid-auto-rows: calc((100vh - 192px) / 12 / 2); // height responsive => (100vh - padding top -padding bottom )12rows / 2
    }
}

.gallery-item {
    width:100%;
    height:100%;
    position:relative;
}

.gallery-item .image {
    width:100%;
    height:100%;
    overflow:hidden;
}

.gallery-item .image img {
    width:100%;
    // height:100%;
    object-fit: cover;
    // object-position:50% 50%; -> dynamic position with fallback 50% 50% center center 
    object-position: var(--object-postion, 50% 50%); // fallback 50 50 / center center
    // cursor: pointer;
    // transition:.5s ease-in-out;
}
