nav.breadcrumb {
    padding-top: 0.25rem; 
    padding-bottom: 0.25rem; 
}
nav.breadcrumb[data-variant="footer"] {
    position:relative;
    width: 100%; 
    overflow: hidden;
}

.crumbs {
    padding: 0;
    margin: 0;
    width: 100%; 

    font-size: 0.875rem;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;  

    li {
        display: block; 
        list-style-type: none;
        margin-right: 5px; 
        flex: 0 1 auto; 
    }
}

.crumb,
.crumb-arrow {
    color: rgba(0,0,0,0.55); 
}
.crumb {
    
    &.--home {
        .btn-icon-wrap svg {
            inline-size: 1em; 
        }
    }
    &.--last {
        font-style:italic; 
    }
}
a.crumb {
    transition: color 160ms ease; 
    &:hover {
        color: var(--color-turquoise-dark);
    }
}

/* Exceptions */
/* Variant Boards */
nav.breadcrumb[data-variant="boards"] {
    position: fixed;
    top: $maxWidthFrame;
    
    left: 0; 
    right: 0; 
    z-index: z('nav');

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @include media-query('mdmax') {
        padding-left: calc($maxWidthFrame * 1.5); 
        padding-right: calc($maxWidthFrame * 1.5); 
    }

    @include media-query('lg') {
        top: calc($maxWidthFrame * 1.5); 
        justify-content: flex-end;
        align-items: flex-end;

        &[data-dir="ltr"] {
            left: auto;
            right: calc($maxWidthFrame * 1.5); 
        }
        &[data-dir="rtl"] {
            right: auto;
            left: calc($maxWidthFrame * 1.5); 
        }
    }
}