/*
 * SWIPERS
*/

// TO DO
// https://codepen.io/hagiang1305/pen/apxvEZ


.swiper-feature {
    // width: 100%;
    // height: 100%;
    // margin: 0 auto;
    
    // default
    width: 100%; 
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;

    .swiper-slide-container {
        text-align: center;
        // font-size: 18px;
        // background: #fff;
        // height: 100%;
        height: auto; 
        width: 100%;
        // max-width: 600px;
        margin:auto;
            /* Center slide text vertically */

        display: flex;
        justify-content: center;
        align-items: center;
    }


    /*Slides */
    .swiper-slide {
        
        height: auto; 

        display: flex;
        align-items: center;
        justify-content: center;

        /*
        @include media-query('boardmin') {
            padding-bottom: 0; 
            padding-left: 12rem;
            padding-left: calc(6rem + 16px);
            padding-right: 6rem; 
        }
        */
    }

    // .swiper-slide .swiper-slide-container {}

    // Quick Fix for Home - might need refinement if gets reused etc
    .swiper-figure {
        height: 100%;
        width: 100%;
        span {
            display: block;
            height: inherit;
            width: inherit;
        }
        img {
            position: absolute;
            height: inherit;
            width: inherit;
            object-fit: cover;

        }
    }


    // control the height of the swoper by max image (media) height
    .swiper-slide .media img {
        height: 40vh;
        
        @include media-query('boardmin') {
            height: 50vh; // control height of slide (equal slight height)
        }
    }

    // overrides 
    // the title on hover etc
    .swiper-slide .media:hover {
        .title {
            opacity: 1;
        }
    }
    // swiper prev next 
    .swiper-button-prev {
        @include media-query('boardmin') {
            left: 16px; // default
        }
    }
    .swiper-button-next {
        @include media-query('boardmin') {
            right: 16px; // default
        }
    }
    @include media-query('boardmin') {
        .swiper-button-prev, .swiper-button-next {
            opacity: 0;
        }
        &:hover {
            .swiper-button-prev, .swiper-button-next {
                opacity: 0.33; 
            }
        }
    }

    /*RTL*/
    &.swiper-rtl {
        .swiper-button-prev {
            @include media-query('boardmin') {
                left: auto; 
                right: 16px; // default + frameborderwidth
            }
        }
        .swiper-button-next {
            @include media-query('boardmin') {
                right: auto;
                left: 16px; // default *2

            }
        }
    }
}


/* Thumbs - Names */
.swiper-feature-thumbs {
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0;

    position: relative;
    width: 100%;
    overflow: hidden;
    // bottom: 0;

    .swiper-slide {
        text-align: center;
        
        width: 50%;
        flex: 1 0 50%;
        // width: 50%;
        // flex: 1 1 100px;

        height: 100%;
        opacity: $softOpacity;
        margin: 0; 
        padding: 0.75rem 0.25rem; 
        line-height: 1;
        transition: opacity 200ms ease-in-out; 
        
        // cursor: pointer;
        @include grab-cursor(); 

        // scale up number of items
        @include media-query('sm') {
            width: 33%;
            flex: 1 0 33%;
        }
        @include media-query('xl') {
            width: 25%;
            flex: 1 0 25%;
        }
        @include media-query('xxl') {
            width: 20%;
            flex: 1 0 20%;
        }
        
    }
    .swiper-slide:hover {
        opacity: .8;
    }
    .swiper-slide-active {
        opacity: 1;
    }

    .swiper-title {
        &[data-variant="desktop"] {
            display: none; 
            @include media-query('md') {
                display: block; 
            }
        }
        &[data-variant="mobile"] {
            display: block; 
            text-align: center;
            @include media-query('md') {
                display: none; 
            }
        }
    }
}

/* SWIPER INTERVIEWS & SWIPER BASE */
.swiper-interviews,
.swiper-slider-base {
    width: 100%; 
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

/* Exceptions */
// .block-image
.swiper-slider-base {
    .block-image {
        margin-top: 0;
        margin-bottom: 0;
    }
}

/* Global 
* SWIPER Overrides 
*/
// Bullet Pagination
.swiper-interviews-pagination,
.swiper-slider-base-pagination {
    --swiper-pagination-bullet-horizontal-gap: 0.5rem;
    --swiper-pagination-bullet-size: 14px;
    --swiper-pagination-color: var(--color-turquoise);

    padding: 0.5rem 0;
    // text-align: right;
}
.swiper-pagination-bullet {
    --swiper-pagination-bullet-inactive-color: var(--color-dark);
    --swiper-pagination-bullet-inactive-opacity: 0.33;
    border-radius: 0; 
}

// Prev Next Arrows
.swiper-button-next, .swiper-button-prev {
    --swiper-navigation-color: var(--color-dark);
    opacity: 0.33; 
    transition: opacity 200ms ease;
    will-change: opacity;
}