[data-template="biosingle"] {
    // limit max width
    .view-page {
        max-width: $maxWidthPageSingle;

        @include media-query('lg') {
            padding-left: 6rem; // correct the offset on biosingle, so bleed will work correctly 
        }
    }
 
    .board-wrapper[data-variant="biosingle-intro"] {
        
        .board-content {
            .board-footer {
                padding: 1rem 0; 
            }
        }

        @include media-query('boardmin') {
            // constrol height
            .board-intro {
                grid-template-rows: minmax(33vh,60vh); 

                margin-bottom: $space-xl;
                margin-bottom: $space-xl-fluid;
            }

            .board-actions {
                display: none; // deactivate for now
                /*
                min-height: 6rem;
                .scroll-indicator {
                    bottom: 20px; // note, the grid on intro accomendates 80px top and bottom white space, so we can offset this a little less
                }
                */
            }

            /*
            .board-intro-wrap {
                // margin-bottom: 6rem; 
            }
            */
        }

        // Rebuild the app board spacing (from .view in app mode) for the reused .board-header
        @include media-query('boardbetween') {
            // make it bleed
            // @include full-bleed-ltr();
            @include semi-bleed-xl-up();
       
            .board-intro-wrap {
                position: relative; // needed for scroll actions
                // Rebuild app page Spaces
                // See mixin wrapper(); 
                // padding-left: calc($maxWidthFrame * 6 * 2); // LTR - accomondate for logo and offset layout
                // padding-left: calc($maxWidthFrame * 6);
                // padding-right: calc($maxWidthFrame * 6);
    
                max-width: $maxWidthBoard;
                // margin-left: auto;
                // margin-right: auto;        
            }

            /*
            .board-intro[data-variant="biosingle"] {
                // padding-left: 6rem; // re-add the side offset
            }
            */
        }

        /* height exception */
        // 1216 = boardmin = xl and MAX height 768
        @media only screen and (max-height: 768px) and (min-width: 1216px) { 
            .board-intro {
                grid-template-rows: minmax(550px,80vh);
            }
            .board-intro .board-content {
                justify-content: flex-start;
                padding-bottom: $boardContentBottomSpacer; 
            }
        }
    }


    // QUICK FIX CORRECT THIS
    .column[style="--columns:12"] {
        // max-width: 800px;
        // margin: 0 auto;

        figure img {
            // force full width
            max-width: initial;
            width: 100%; 
        }
    }


    figcaption {
        @include captiontext();
    }

}

/* RTL */
[dir="rtl"] [data-template="biosingle"] {
    .view {
        
        @include media-query('lg') {
            // padding-top: 0; 
            padding-left: 6rem; // correct the offset on home, so bleed will work correctly 
            padding-right: 6rem; // correct the offset on home, so bleed will work correctly 
        }
    }

    /*
    .board-wrapper[data-variant="biosingle-intro"] {
        @include media-query('boardbetween') {
            // make it bleed
            // margin-left: initial; // make sure to reset
            // @include full-bleed-rtl(); 
        }
    }

    .board-intro[data-variant="biosingle"] {
        @include media-query('boardbetween') { 
            // padding-left: 0; // re-add the side offset to the right side
            // padding-right: 6rem;
        }
    }
    */
}
