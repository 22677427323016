.cards {

    --column-count: 1;
    --grid-gap: 0.5rem; 
    @include media-query('sm') {
        --column-count: 2;
        --grid-gap: 1rem; 
    }
    @include media-query('xl') {
        --column-count: 3;
        --grid-gap: 1.5rem; 
    }

    grid-gap: var(--grid-gap);
    display: grid;
    grid-template-columns: repeat(var(--column-count),minmax(0,1fr));
    // grid-template-columns: repeat(auto-fill,minmax(15rem,1fr));

    
}
.card {
    // --card-container-border-radius: clamp(4px,(var(--column-width,0px) - 32px) * 0.025,8px);
    // border-radius: calc(var(--card-container-border-radius) + 2px);
    // background: #181818;
    background: var(--color-gray-light); 
    
     // -webkit-box-flex: 1;
    // -ms-flex: 1;
    // flex: 1;
    // isolation: isolate;
    padding: 0.5rem;
    position: relative;
    // -webkit-transition: background-color .3s ease;
    transition: background-color .3s ease, color .3s ease;
    width: 100%;

    &:hover {
        // background: #282828;
        background: var(--color-turquoise); 
        color: var(--color-light);

        .card-title[data-variant="has-alt-title"] {
            .card-title-main {
                // transform: translateX(-100%);
                opacity: 0; 
            }
            .card-title-alt {
                opacity: 1;
            }
        }
    }
}

.card-link {
    // a wrapper around all card elements
    &[data-variant="boxed"] {
        display: block;
        cursor: pointer;
        text-decoration: none;
        color: var(--color-dark); 
    }
}

.card .media-image {
    border-width: var(--border-width, 0px); 
    z-index: 1;
}

// .card-header {
//     padding: 0.25rem 0; 
// }

.card-title {
    line-height: 1;
    padding: 0.75rem 0 0.5rem 0; 

    &[data-variant="has-alt-title"] {
        position: relative; 
        display: flex;
        justify-content: space-between;
        .card-title-main,
        .card-title-alt {
            display: block;
            line-height: 1;
        }
        .card-title-main {
            // transform: translateX(0);
            // will-change: transform;
            // transition: transform .3s ease;
            opacity: 1; 
            will-change: opacity;
            transition: opacity .3s ease;
        }
        .card-title-alt {
            color: var(--color-light); 
            opacity: 0; 
            will-change: opacity;
            transition: opacity .3s ease;
            // position: absolute;
            // top: 0; left: 0;
        } 
    }
}
// .card-title-main {}
.card-title-alt {
    display: none; // only display if data variant "has-alt-title" is set (and content is there ;)
}



/* Exceptions */
.cards[data-variant="biographies"] {
    .card .media-image .image {
        overflow: hidden; 

        @include media-query('sm') {
            height: 350px;
        }
    }
}

/* Exception: .card .media*/
//.card .media {
    // cursor: default !important; 
// }

/* Invert Title Logik */ 
.card .media .title {
    opacity: 0;
}
.card:hover .media .title {
    opacity: 1;
}
  

/*
.card[data-variant="card-mini"] {
    .card-title{
        
    }
}
*/
