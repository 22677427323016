[data-template="home"] { 
    .swiper-feature .swiper-slide {
        .media .caption {
            display: none;
        }
        .media:not(:hover) .title {
            opacity: 0;
        }
    }
}

[data-template="bios"] {
    .media .caption,
    .media .copy {
        display: none;
    }
}

[data-template="interviews"] {
    .media .title span,
    .media .caption,
    .media .copy {
        display: none;
    }
}

[data-template="board_video"] {
    .media .title span {
        display: none;
    }
}