// All Boards
[data-template*="board_"] {
    .view-app {
        width: 100%; // 100vw should be percentage here as 100 vw again (weihtin 100vw panel-view) causes offset left by scrollbar width AS Well as overflow-x on body!
        @include media-query('boardmin') { 
            // make it 100 vh, so we can center the boards
            // NOTE that the scroll container is not body anymore, but now becomes the board-wrapper
            // the scrollbar of board wrapper is hidden behind the frame but still scroll is usable in edge cases, where the screen becomes wide, but not high
            height: 100vh; 
        }
    }
    .board-wrapper {
        // height: 100%;
        // width: 100%;
        // position: relative;

        // ALWAYS TRY TO CENTER ON LARGE
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        min-height: 100%;
        width: inherit;

        max-width: $maxWidthBoard;
        margin: 0 auto;
    }
    .board {
        // position: absolute;
        // top: 0; left: 0; bottom: 0; right: 0;
        // height: inherit;
        // width: inherit;

        width: inherit;

        grid-gap: 2rem; 
        @include media-query('boardmin') { 
            grid-gap: 0.625rem; // 0.625rem 10px
        }
    }

    .board-content {
        .text-board:not([data-variant="authors"]) {
            max-width: 70ch; // Text Format
            @include responsive-font(1.5vh, 15px, 18px, 15px); // HEIGHT DEPENDANT!!! so we achieve nice results on intermediate resolution such as 1366x768 / 1200x600 and 1600x1200
        }

        .board-title {
            @include responsive-font(5vw, 18px, 36px, 21px);
        }

        @include media-query('noboardmax') { 
            display: block;
            position: relative;
            overflow: hidden; 

            padding-bottom: $boardContentBottomSpacer; // see vars
        }
    }
}



/* RTL */
/*
.board[data-dir="rtl"] {
    [data-text-position='left'] {

    }
}
*/