$col-padding: 5vw; 

.flex {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative; 
    width: 100%;


    // interviews
    &[data-variant*='flex-interview'] {
        @include media-query('mdmax') {
            .flex-col {
                // media
                &:first-child {
                    order: 2; 
                }

                &:last-child {
                    margin-bottom: $col-padding; 
                }
            }
        }
        @include media-query('lg') {
            flex-direction: row;
            align-items: flex-start; // avoid initial align-items: stretch, cause we dont want the media item to stretch (most likel its 16:9)
            .flex-col {
                &:first-child {
                    // order: 1; 
                    width: calc(100% / 10 * 6);
                }
                // text
                &:last-child {
                    // order: 1; 
                    width: calc(100% / 10 * 4);
                    padding-left: $boardHorizontalSpaceMin; 
                    padding-left: $boardHorizontalSpaceFluid;
                }
            }
        }
        @include media-query('xxl') {
            .flex-col {
                &:first-child {
                    // order: 1; 
                    width: calc((100% / 3) * 2);
                }
                // text
                &:last-child {
                    // order: 1; 
                    width: calc(100% / 3);
                    // padding-left: $col-padding; 
                }
            }
        }
    }
}

[dir="rtl"] {
    @include media-query('lg') {
        .flex .flex-col:last-child {
            padding-left: 0; 
            padding-right: $boardHorizontalSpaceMin; 
            padding-right: $boardHorizontalSpaceFluid;
        }
    }
}