
[data-template="interviews"] {
    .article-interview {
        padding-top: calc($maxWidthFrame * 2); // make room from scroll to so we dont get below the frame when scrolling

        @include media-query('lg') { 
            padding-bottom: calc($maxWidthFrame * 2); 
        }
    }

    .article-interview header {
        @include media-query('lg') { 
            padding-top: 1rem;
        }
    }
    
    .article-interview .text {
        @include media-query('mdmax') { 
            max-width: initial;
            width: 100%;
            columns:  320px 2;
            column-gap: 3rem;
        }
    }
}
