/*
 * README - About Boards
 * Base Boards follow the logic, that scroll should be avoided on all screen sizes
 * As a Base, we define the the surrounding view with 100vh & 100 vw and the inner wrappers extend to fit with 100% sizes.
 * Finally the board (which most likely is a grid) is absolutely positioned and inherits 
 * On small screens we need to adjust the grid rows as vh relative values and possibly also the the text sizes as vh (around 1.5vh)
*/
/*
* Body Element
*/
/*
html, body {
   // height: 100%;
   
}
*/
.body {
    
    @at-root #{&}-app {
        width: 100%;
        // height: 100%;
    }
    
    @at-root #{&}-page {
        width: 100%; 
    }
}



/*
* #id App (Panel)
* "new body" housing all EXCEPT modals and frame
*/

.panel {
    
    &[data-variant='app'] {
        // second version - just hide the scrollbars but let y be scrollable on boards
        // height: 100%;
        position: relative;
        width: 100%; 
        overflow-x: hidden;
        
    }
    
    &[data-variant='page'] {
        position: relative;
        width: 100%; 
        // height: 100%; 
        // THE FOOTER -> Breadcrumbs SEEMS TO BE THE PROBLEM
        // overflow-x: hidden; // obviously RTL pages tend to break out in chrome if overflow is not set! Thats a bummer for position sticky

    }
}


/*
* Main Element (Panel View)
*/

.panel-view {
    // first version (app)
    // position: relative;
    // overflow-x: hidden;

    &[data-variant='app'] {
        
    }
    &[data-variant='page'] {
        // position: relative;
        // width: 100%;
    }
}



/*
* Main Element Inner (Panel View)
* panel-view > div 
*/
.view {
    @include wrapper(); 

    padding-top: calc($mobileHeaderHeight + $maxWidthFrame); 
    padding-bottom: $mobileHeaderHeight; // tmp

    // app 
    @at-root #{&}-app {
        @include media-query('lg') {
            padding-top: $mobileHeaderHeight; // tmp
        }
    }
    // page
    @at-root #{&}-page {
        width: 100%;
        max-width: $maxWidthPage;
    }

    // RTL  - Correct the wrapper mixin settings
    &[data-dir="rtl"] {
        @include media-query('lg') {
            padding-right: calc($maxWidthFrame * 6 * 2); // LTR
            padding-left: calc($maxWidthFrame * 6);
        }
    }
}
