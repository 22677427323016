// grid
.cardlets {
    display: grid;
    // grid-template-columns: repeat(auto-fill,minmax(16rem,1fr));
    // grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fill,minmax(20rem,1fr));
    grid-gap: 2rem;

    width: 100%; 
}

.cardlet {
    position: relative;
    line-height: 1;

    display: grid;
    grid-template-columns: 6rem auto;
    // grid-template-rows: auto 38px;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "figure header"
        "figure footer";
}

.cardlet-figure {
    grid-area: figure;
}

.cardlet-header {
    // grid-area: header;
    // padding: 0.625rem 0.75rem;
    grid-area: header;
    grid-row: 1 / 3; 
}
.cardlet-footer {
    grid-area: footer;
    // padding-top: 0.5rem;
    // padding-bottom: 0.5rem;
    // padding: 0 0.75rem;
}
.cardlet-header,
.cardlet-footer {
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
}

.cardlet-figure,
.cardlet-header,
.carlet-footer {
    position: relative;
    z-index: 1;
}


// Styles
.cardlet-title {
    .title-main {
        // font-size: 1.125rem;
        // font-size: clamp(1.125rem, 1.075rem + 0.25vw, 1.375rem);
        display: block;
        font-size: 1rem;
        font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
        margin-bottom: 0.25rem;
    }  


    .title-subline,
    .cardlet-text {
        display: block;
        font-size: 0.825rem;
        font-style: italic;
        // font-weight: 400;
        text-overflow: ellipsis;
        // white-space: nowrap;
        line-height: 1rem;
        overflow: hidden;
        max-width: 26ch; 
        opacity: 0.77;
    }
}

.cardlet-figure {
    width: 96px;
    height: 96px; 
    .image {
        height: 100%;
        overflow: hidden;
        width: 100%;
    }
    img {
        position: relative;
        inline-size: 100%;
        // height: auto;
        height: 100%;
        object-fit: cover;
        object-position: var(--object-postion, 50% 50%); // fallback 50 50 / center center
    }
}

/* Queries */
@include media-query('mdmax') {
    .cardlets {
        grid-gap:1.5rem;
        // grid-template-columns: 72px 1fr;
    }

    .cardlet-figure {
        width: 72px;
        height: 72px; 
    }

    .cardlet-footer, .cardlet-header {
        padding-left: 0;
    }
} 


/* Font Family Adjustments on LTR Only  */
[dir="ltr"] .cardlet-title .title-subline {
    font-family: var(--font-family-sansLatin); 
}

/* link */
.cardlet-link {
    position: relative;
    outline: 0;

    text-decoration: none;
    
    transform: scale(1);
    will-change: transform color;
    transition: transform .2s ease-out, color .2s ease;
    
    &:before {
        position: absolute;
        content: ' ';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // z-index: 1;
    }
}

/* Exceptions */ 
// styles by variant && state 
.cardlets[data-variant='interviews'],
.cardlets[data-variant='bios'], 
.cardlets[data-variant='boards'] {
    .cardlet-link {
        
        &:before {
            transition: background-color .2s ease;
            will-change: background-color;
        }
        &:hover {
            color: var(--color-dark);
            transform: scale(1.04);
        }
        &.active,
        &:hover:before {
            // background: rgba(0,0,0,0.15);
            background-color: rgba(255,255,255,0.15);
            color: var(--color-dark);
        }
    }
}

// Main Menu
.cardlets[data-variant='interviews'],
.cardlets[data-variant='bios'] {
    
    .cardlet-link {
        // color: var(--color-light);
        color: rgba(243,243,243,0.8); // var--color-light
    }
}

// Boards
.cardlets[data-variant='boards'] {
    grid-template-columns: repeat(auto-fill,minmax(16rem,1fr));

    @include media-query('mdmax') {
        margin-bottom: $space-l;
        margin-bottom: $space-l-fluid;
    }

    .cardlet-link {
        // color: var(--color-dark);
        color: rgba(26,26,26,0.8); // var--color-dark
    }
}



/*
.cardlets[data-variant='interviews'],
.cardlets[data-variant='bios']  {
    .cardlet-figure span {
        display: block;
        position: relative;
        overflow: hidden;
        // border-radius: 50%;
    }
}
*/