// [data-template="board_cite"] {
.tpl-board_cite {

    background-color: var(--color-turquoise);
    .text-board {
        color: var(--color-light);
    } 
}

.board-cite.cite-grid {

    @include media-query('sm') { 
        // grid-template-rows: repeat(12, 1fr); // equals cols from .gallery-grid (base settings)
        
        // grid-template:"container";
        // grid-template-areas: "container";
        // grid-template-rows: 1fr; // need to set a row so everything stays in place for positioning within container! (see css tricks)
        display: grid; 
        grid-template: "container" 1fr;
   
        place-items: center;
        // place-content: center;
        place-content: center;
        > * {
            grid-area: container; // one single container
            
            padding: 5vh;
            max-width: 1000px;
        }

        .board-content {
            place-self: center center;
            // grid-row: 1; // need to reset mobile definitions to 1/1 for positioning & overflow to work properly
            // grid-column: 1; // need to reset mobile definitions to 1/1 for positioning & overflow to work properly

            /* HEIGHT Exception */
            @media only screen and (max-height: 768px) and (min-width: 1216px) { 
                padding-bottom: $boardContentBottomSpacer; 
            }
        }
    }
}

/* Text Formate Board Cite*/
.text-board[data-variant="cite"] {
    padding: 1rem; 
}
.cite-board {
    position: relative;
    .cite-svg {
        position: absolute;
        top: -0.5rem;
        left: -1rem; 
        @include responsive-font(3vh, 64px, 84px, 64px);
        isolation: isolate;
        svg {
            inline-size: 1em;
            fill: currentColor; 
            opacity: 0.12;
        }
    }

}
.cite-board p {
    font-family: var(--font-family-sansLatinItalic); 
    font-weight: normal;
    @include responsive-font(1.85vh, 18px, 32px, 21px); // HEIGHT DEPENDANT!!! so we achieve nice results on intermediate resolution such as 1366x768 / 1200x600 and 1600x1200
    line-height: 1.65;
}
.cite-board cite {
    @include captiontext(); 
    font-style: normal !important;
}

/* RTL */
[dir="rtl"] {
    .cite-board {
        .cite-svg {
            position: absolute;
            top: -0.5rem;
            left: auto; 
            right: -1rem;
        }
    }
}