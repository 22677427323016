/*
* Video Board
* Default Text Position is (always) "right" // RTL == left
* Similar to template Board "Split A" (One Image in Split Mode)
*
* FINSHED
*/

// TEXT RIGHT 
.board-video {
    display: grid;
    grid-auto-rows: unset; // reset rows
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-columns: 100%;

    .video-wrapper {
        grid-column: 1; 
        grid-row: 1;
    }
    .board-content {
        grid-column: 1; 
        grid-row: 2;
    }   

    @include media-query('boardmin') { 
        // grid-template-rows: $mobileHeaderHeight 1fr minmax(min-content, 500px) 1fr $mobileHeaderHeight; // 5 rows spacer 1f minmax(for image) 1fr spacer
        // grid-template-columns: 1fr calc(100% / 12) 30% ; // 3 cols: 30% content 100%12 (overlapping zone 12grid) 1f image
        grid-template-rows: 3vh minmax(50vh, calc(100vh - 33vh)) 3vh;
        grid-template-columns: 1fr calc(100% / 12) 30%;

        .video-wrapper {
            grid-column: 1 / 3; 
            grid-row: 2 / 3;

            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .board-content {
            grid-column: 3 / 4; 
            grid-row: 2 / 3;

            display: flex;
            flex-direction: column;
            justify-content: center;

            padding-left: $boardHorizontalSpaceMin; // FALLBACK
            padding-left: $boardHorizontalSpaceFluid; // CLAMP
            padding-bottom: 0;
        }
    }
    @include media-query('boardbetween') { 
        .video-wrapper,
        .board-content {
            grid-row: 1 / 4;
        }
    }

    /* height exception */
    @media only screen and (max-height: 768px) and (min-width: 1216px) { 
        
        grid-template-columns: 50% calc(100% / 12) 40%;

        .video-wrapper {
            min-height: 480px; 
            grid-row: 1 / 2;

            justify-content: flex-start; 
        }
        .board-content {
            grid-row: 1 / 2;
            justify-content: flex-start; 
        }
    }
}

/* RTL - Text column is left  */
.board-video[data-dir="rtl"] { 
    @include media-query('boardmin') { 
        // grid-template-columns: 1fr calc(100% / 12) 30%;

        // .video-wrapper {}
        .board-content {
            padding-left: 0;
            padding-right: $boardHorizontalSpaceMin; // FALLBACK
            padding-right: $boardHorizontalSpaceFluid; // CLAMP
        }
    }
}
