.page {
    @at-root #{&}-sidebar {
        // position: relative;
        // width: 100%; 
        // overflow: hidden;
        // overflow: auto; // scroller
        
        @include media-query('md') {
            display: flex;
            justify-content: space-between;
        }

        .page-content {
            @include media-query('md') {
                width: calc(100% - 240px);
                padding-right: 5vw;
            }
            @include media-query('xl') {
                width: calc(100% - 360px);
            }
        }

        .sidebar {
            position: relative; 
            
            @include media-query('md') {
                
                width: 240px;
                flex-shrink: 0;

                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;
                // height: 100%;
                // overflow: auto;
            }
            @include media-query('xl') {
                width: 360px;
            }
        }

        .sidebar-inner {
            @include media-query('md') {
                position: relative; // fallback
                position: -webkit-sticky;
                position: sticky;
                top: calc($mobileHeaderHeight + $maxWidthFrame);
            }
        }
        
    }
}

[data-dir="rtl"] {
    .page-sidebar {
        .page-content {
            @include media-query('md') {
                padding-right: 0;
                padding-left: 5vw;
            }
        }
    }
}

/*
.page {
    @at-root #{&}-sidebar {
        position: relative;
        width: 100%; 

        display: grid;
        grid-template-columns: 100%; 
        // grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content); 
        grid-template-rows: auto auto;
        grid-gap: 3rem;
        
        @include media-query('md') {
            grid-template: [page-left] "content sidebar" [page-right]
                        / 1fr 350px;
        }

        article {
            @include media-query('md') {
                grid-area: content; 
            }
        }

        .sidebar {
            @include media-query('md') {
                grid-area: sidebar; 
                height: 100%;
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;
            }
        }

        .sidebar-inner {
            @include media-query('md') {
                position: relative; // fallback
                // position: -webkit-sticky;
                position: sticky;
                top: calc($mobileHeaderHeight + $maxWidthFrame);
            }
        }
        
    }
}
*/