/*
* 1 Banner
*/
.board-gallery-three[data-variant="banner"] {
    grid-auto-rows: unset; // reset rows
    // grid-template-rows: 60vh 40vh 40vh minmax(min-content, max-content);
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-columns: 100%;

    // mobile
    .board-figure {
        grid-column: 1; 
    }
    .board-figure-1 {
        grid-row: 1;
    }
    .board-figure-2 {
        grid-row: 3;
    }
    .board-figure-3 {
        grid-row: 4;
    }

    .board-content {
        grid-column: 1; 
        grid-row: 2;

        padding: 0; // reset
    }

    // desktop
    @include media-query('md') { 
        grid-template-rows: minmax(32vh, 35vh) minmax(32vh, 35vh) minmax(min-content, max-content);
        grid-template-columns: repeat(12, 1fr);

        padding-bottom: $boardContentBottomSpacer; // space end for nav

        .board-figure-1 {
            grid-column: 1 / 8; // 13
            grid-row: 1 / 3;
        } 
        .board-figure-2 {
            grid-column: 8 /  13;
            grid-row: 1;
        } 
        .board-figure-3 {
            grid-column: 8 /  13;
            grid-row: 2;
        } 
        .board-content {
            grid-column: 1 / 13; 
            grid-row: 3;
        }
        .board-content .text-board {
            max-width: initial;
            width: 100%;
            columns:  360px 3;
            column-gap: 3rem;
        }
    }

    @include media-query('boardmin') { 
        grid-template-rows: minmax(45vh, 55vh) minmax(min-content, max-content);

        .board-figure-1, .board-figure-2, .board-figure-3 {
            grid-row: 1 / 2;

            // min-height: 35vh; 
            // max-height: 42vh;
        }
        .board-figure-1 {
            grid-column: 1 / 5; // 13
        } 
        .board-figure-2 {
            grid-column: 5 /  9;
        } 
        .board-figure-3 {
            grid-column: 9 /  13;
        } 
        .board-content {
            grid-column: 1 / 13; 
            grid-row: 2;

            padding-top: $boardHorizontalSpaceMin; // FALLBACK
            padding-top: $boardHorizontalSpaceFluid; // CLAMP
        }
    }
    /*
    @include media-query('boardbetween') { 
        // grid-template-rows: minmax(50vh, 58vh) minmax(min-content, max-content);
    }
    */

}