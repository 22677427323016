// Icon Buttons
.btn-icon {
    display: block;
    outline: none;
    appearance: none;
    // color: red;
    background: none;
    border: 0px;
    // font-weight: bold;
    cursor: pointer;
    // pointer-events: all;
    line-height: 1;

    transition: all 120ms ease; 
}

.btn-icon-wrap {
    display: block;
    // color: var(--color-black);
    svg {
        // color: inherit;
        // fill: var(--color-black);
        inline-size: 3.5rem;
        fill: currentcolor;
          
    }
}

// .btn-icon-label {}

// buttons by modifier
// override defaults 
.btn-icon--close {
    .btn-icon-wrap svg {
        inline-size: 1.25rem;
    }

    // active state & hover
    &:hover {
        color: var(--color-turquoise-dark); 
    }
}

.btn-icon--caret {
    display: block;
    position: relative;
    min-width: 50px;
    height: 1em;

    .btn-icon-wrap {
        display: block;
        position: absolute;

        height: 10px; 
        height: clamp(0.5rem, 0.45rem + 0.25vw, 0.75rem); // 8px @ 320 - 12px @ 1920
        width: 10px; 
        width: clamp(0.5rem, 0.45rem + 0.25vw, 0.75rem); // 8px @ 320 - 12px @ 1920
        right: 0;
        bottom: 0.1425em; // adapt proportionally to parent font size

        overflow: hidden;

        transform: rotate(0deg);
        transition: transform 160ms ease-out;

        svg {
            display: block;
            height: 100%;
            width: 100%; 
            // inline-size: 0.625rem; // 10px
            inline-size: initial; 
        }
    }
}

// Buttons Collape / Language & Caret transitions
// menu btn collapse caret
.btn-collapse {
    &.btn-icon--caret {
        &:hover,
        &.is-active {
            .btn-icon-wrap {
                transform: rotate(45deg);
            }
        }
    }
}

// button language (with caret)
.btn-language {
    font-family: var(--font-family-headlineLatin); 
        display: inline-block;
        padding: 0 15px; 
        font-weight: 700;
        font-size: 1.75rem; 
        text-transform: uppercase;
        // letter-spacing: -0.0875rem; // 3px
        letter-spacing: -0.025em;
        line-height: 1;
    
    // span { }
    // .btn-icon-label[data-variant="current-lang"] {}
    
    &.btn-icon--caret {
        .btn-icon-wrap {
            height: 10px; 
            height: clamp(0.5rem, 0.475rem + 0.125vw, 0.625rem);; // 8px @ 320 - 10px @ 1920
            width: 10px; 
            width: clamp(0.5rem, 0.475rem + 0.125vw, 0.625rem);; // 8px @ 320 - 10px @ 1920
        } 
        &:hover {
            .btn-icon-wrap {
                transform: rotate(-90deg);
            }
        }
    }
}
// langauge selectors (in modal)
.btn-icon--flag {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    padding: 0.5rem;
    border: 3px solid var(--color-dark);
    margin: 1rem;
    min-width: 100px; 

    .btn-icon-wrap {
        svg {
            min-height: 3.5rem; 
        }
    }

    // active state & hover
    &[aria-current], &:hover {
        color: var(--color-turquoise-dark); 
        border-color: var(--color-turquoise);
    }
}



// RTL
[dir="rtl"] {
    // btn collapse caret
    .btn-collapse.btn-icon--caret {
        .btn-icon-wrap {
            transform: rotate(90deg);
            right: auto;
            left: 0;
        }
        &:hover,
        &.is-active {
            .btn-icon-wrap {
                transform: rotate(45deg);
            }
        }
    }

    // btn language 
    .btn-language.btn-icon--caret {
        .btn-icon-wrap {
            transform: rotate(90deg);
            right: auto;
            left: 0;
        }
        &:hover {
            .btn-icon-wrap {
                transform: rotate(180deg);
            }
        }
    }
}

/*
* Edit Cooki (button, span, a)
*/
.edit-cookie {
    outline: none;
    appearance: none;
    background: none;
    border: 0px;
    cursor: pointer;
}


/*
* Links (Icon Links) - Inline
*/

.link-icon {
    svg {
        inline-size: 1em;
        fill: currentcolor;
    }

    transition: color 160ms ease; 

    &:hover {
        color: var(--color-turquoise-dark); 
    }
}
