/*
* 2 Portrait
* FINISHED - BUT CONTROL AGAIN
*/
.board-gallery-two[data-variant="portrait"] {
    grid-auto-rows: unset; // reset rows
    // grid-template-rows: 50vh 50vh minmax(min-content, max-content);
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    /*
    @include media-query('sm') {
        grid-template-rows: repeat(12,1fr);
    }
    */
    
    // mobile
    .board-figure,
    .board-content {
        grid-column: span 12; 
    }
    .board-figure-1 {
        grid-row: 1;
    }
    .board-figure-2 {
        grid-row: 2;
    }
    .board-content {
        grid-row: 3;
    }
    .board-content .text-board {
        @include media-query('noboardmax') {
            max-width: initial;
            width: 100%;
            columns:  320px 2;
            column-gap: 3rem;
        }
    }
    
    // two rows
    @include media-query('md') {
        grid-template-rows: 50vh minmax(min-content, max-content);
        grid-template-columns: 1fr 1fr; // 3 cols: 30% content 100%12 (overlapping zone 12grid) 1f image

        .board-figure {
            grid-row: 1; 
        }
        .board-figure-1 {
            grid-column: 1;
        }
        .board-figure-2 {
            grid-column: 2;
        }
        .board-content {
            grid-column: 1 / 3;
            grid-row: 2;
        }
    }
    // five rows - 3 columns
    @include media-query('boardmin') {
        // grid-template-rows: $mobileHeaderHeight 1fr minmax(min-content, 500px) 1fr $mobileHeaderHeight; // 5 rows spacer 1f minmax(for image) 1fr spacer
        grid-template-rows: 5vh minmax(33vh, calc(100vh - 33vh)) 5vh;
        grid-template-columns: 1fr 1fr 30%; // default text right!! == 30%
        .board-figure {
            grid-row: 2 / 3; 
        }
        /*
        .board-figure-1 {
            // grid-column: 1;
        }
        .board-figure-2 {
            // grid-column: 2;
        }
        */
        .board-content {
            grid-column: 3;
            grid-row: 2 / 3;

            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    // DO or Dont make Larger in height ?!

    @include media-query('xxl') {
        .board-figure {
            grid-row: 1 / 4; 
        }
    }


    /* height exception */
    @media only screen and (max-height: 768px) and (min-width: 1216px) { 
        .board-figure {
            grid-row: 1 / 4; 
        }
        .board-content {
            justify-content: flex-start;
            grid-row: 1 / 4;
            
        }
        .board-content .text-board {
            padding-bottom: $boardContentBottomSpacer; 
        }
    }


    // override text position (right is default - see above)
    &[data-text-position='left'] {
        @include media-query('boardmin') {
            // grid-template-columns: 25% 1fr 1fr;  // default text right!! == 30%
            .board-figure-1 {
                grid-column: 2;
            }
            .board-figure-2 {
                grid-column: 3;
            }
            .board-content {
                grid-column: 1;
                
                // set paddings
                padding-right: $boardHorizontalSpaceMin; // FALLBACK
                padding-right: $boardHorizontalSpaceFluid; // CLAMP
            }
        } 
        @include media-query('xxl') {
            grid-template-columns: 25% 1fr 1fr;
        }
    }
    &[data-text-position='right'] {
        @include media-query('boardmin') {
            .board-content {  
            // set paddings
                padding-left: $boardHorizontalSpaceMin; // FALLBACK
                padding-left: $boardHorizontalSpaceFluid; // CLAMP
            }
        }
        @include media-query('xxl') {
            grid-template-columns: 1fr 1fr 25%;
        }
    }
}

/*
* RTL HANDLING
* NOTE: you could set the following directly on the container, to always be ltr 
*/
// lets fix RTL 
// since we want the container to be set by panel user 
// we need to change the behavior of browsers 
// either by setting direction manually on grid container OR 
// by using named lines (the latter we do not have, we'd have to refactor the whole css above)
.board-gallery-two[data-dir="rtl"] {
    @include media-query('boardmin') { 
        direction: ltr; // dont let browser set rtl 
        .board-content {
            direction: rtl; // let the text be RTL of Course!!!
        }
    }
}