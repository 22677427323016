$calcBleed: calc(($maxWidthBoard - $maxWidthPage) / 2 * -1); // calc the extended / stretched view of the board wrapper
$calcBleedWithOffset: calc((($maxWidthBoard + 6rem - $maxWidthPage) / 2)  * -1); // 6 rem offset of .view

.board-wrapper[data-variant="home-feature"] {
    
    position: relative;
    max-width: $maxWidthBoard;
    
    // Bleed on XXL UP
    @include media-query('xxl') {
        // @include semi-bleed-xl-up();
        // the bleed
        margin-left: $calcBleedWithOffset;
        margin-right: $calcBleed;
    }
}

.board-feature {
    width: 100%;
    @include media-query('boardmin') {
        display: grid; 
        grid-template-areas: "swip txt"
                             "tbs tbs"
                             "scrl scrl";
        // grid-template-rows: calc(100vh - 300px) 150px 150px;
        // grid-template-rows: 580px 150px 150px; // first row controlls the height of the feature swiper
        grid-template-rows: minmax(min-content, max-content) 150px;
        grid-template-columns: 58% 1fr;
        grid-gap: 4rem 2rem ; 
    }
    @include media-query('boardbetween') {
        // grid-template-columns: 67% 33%;
        // grid-gap: 4rem 3rem ; 
    }
}
.board-swiper {
    @include media-query('boardmin') {
        grid-area: swip;

        display: flex;
        justify-content: center;
        align-items: center;
        
        .board-content-inner {
            flex: 1 1 auto;
            position: relative;
            // padding: 0 50px;
            width: 100%;
        }
        /*
        .swiper-container {
            // height: 100%;
        }
        .swiper-container .media {
            // max-height: 100%;
        }
        */
    }

    @include media-query('noboardmax') {
        @include marginL();
    }
   
}

.board-swiper-thumbs {
    
    // .board-content-inner { }

    .swiper-container {
        border-top: 1px solid $borderGray;
        border-bottom: 1px solid $borderGray;
    }

    @include media-query('boardmin') {
        grid-area: tbs;
    }

    @include media-query('noboardmax') {
        @include marginM();
    }
}

.board-feature-content { 
    // @include wrapperDownOnBleed(); // forces the paddings left right down lg

    display: flex;
    // justify-content: flex-start;
    justify-content: center;
    align-items: center;
    
    .board-content-inner {
        margin: 0 auto;
        text-align: center;
        max-width: $maxSectionHeaderWidth;
    }

    .hero-text {
        @include responsive-font(1.5vh, 15px, 18px, 15px);
    }

    @include media-query('boardmin') {
        grid-area: txt;

        .board-content-inner {
            text-align: left;
        }
    }

    /* height exception */
    @media only screen and (max-height: 768px) and (min-width: 1216px) { 
        .hero-content [data-variant*="logo"], 
        .social {
            display:none; 
        }
    }
}
/*
.board-actions {
    @include media-query('boardmin') {
        grid-area: scrl;

        display: block; 
        position: relative;
    }
}
*/

/* Elements */
/* Hero */
.hero[data-variant="intro-profile"] {
    .hero-content {
        margin-bottom: $boardVerticalSpaceMin; 

        p {
            @include marginM(); 
        }
    }
    // RTL & LTR Spacing Left Right
    &[data-dir="ltr"] {
        @include media-query('boardmin') {
            padding-left: 0.5rem; 
        }
    }
    &[data-dir="rtl"] {
        @include media-query('boardmin') {
            padding-right: 0.5rem;
        } 
    }
}

/* SVG LOGO */
// link logo
// logo
.svg-wrap[data-variant*="logo-outline"] {
    display: inline-block;
    margin-bottom: calc($boardVerticalSpaceMin / 2); 
    color: var(--color-dark); 
    &:hover {
        .image svg {
            #a, #b {
                opacity: 1;
                transition: opacity 280ms ease; 
            }
        }
    }

    .image {
        display:block;
        max-width: 128px;
        height: 128px; 
        text-align: center;
        margin: 0 auto; 
    }
    .image svg {
        height: 100%;
        // 
        // outlin
        #c, #d {
            fill: currentColor;
        }
        // colorfill bg
        #a, #b {
            opacity: 0;
        }
    } 
}
/* Nav Links */
.nav-links {

    &[data-variant="board-swiper-thumbs"] {
        // padding-left: calc($maxWidthFrame * 2);
        // padding-right: calc($maxWidthFrame * 2);

        display: flex;
        align-items: center;
        // justify-content: space-between; // with "explore"
        justify-content: flex-end;

        span, a {
            flex: 0 1 auto; 

            display: block;
            line-height: 1;
            padding: 0.5rem 0; 
            color: var(--color-dark);
            text-decoration: none;
            
            transition: opacity 200ms ease-in-out; 
            will-change: opacity;
        }
        span {
            opacity: $softOpacity;
        }
        // .nav-links-Label {}
        .nav-links-link {
            transition: color 160ms ease;
            &:hover, &:focus {
                color: var(--color-turquoise-dark);
                // opacity: 1;
            }

            svg {
                inline-size: 1em;
                fill: currentcolor;
            }
        }
    }

}

/*
[dir="rtl"] {
    .board-content[data-variant="biographies"] {
        .board-header {
            right: auto; 
            left: $maxWidthFrame;
        }
    }
    .board-content[data-variant="interviews"] {
        .board-header {
            left: auto; 
            right: $maxWidthFrame; 
        }
    }
}
*/ 

[dir="rtl"] {
    .board-feature-content {
        @include media-query('boardmin') {
            .board-content-inner {
                text-align: right;
            }
        }
    }
}