@mixin screen-reader-only() {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

// layout
@mixin wrapper() {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 0 auto;

    @include media-query('sm') {
        padding-left: calc($maxWidthFrame * 2);
        padding-right: calc($maxWidthFrame * 2);
    }

    @include media-query('lg') {
        padding-left: calc($maxWidthFrame * 6 * 2); // LTR - accomondate for logo and offset layout
        padding-right: calc($maxWidthFrame * 6);
    }
}

@mixin wrapperDownOnBleed() {
    // corrects the side padding on full bleed layouts down 
    @include media-query('lgmax') {
        width: 100%; 
        margin: 0 auto !important;
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
    @include media-query('xsmax') {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    
    }
}

//spacing
@mixin marginS() {
    margin-bottom: $space-s;
    margin-bottom: $space-s-fluid;
}
@mixin marginM() {
    margin-bottom: $space-m;
    margin-bottom: $space-m-fluid;
}
@mixin marginL() {
    margin-bottom: $space-l;
    margin-bottom: $space-l-fluid;
}
@mixin marginXL() {
    margin-bottom: $space-xl;
    margin-bottom: $space-xl-fluid;
}
@mixin marginXXL() {
    margin-bottom: $space-xxl;
    margin-bottom: $space-xxl-fluid;
}

// bleed
@mixin  full-bleed-ltr() {
    width: 100vw;
    margin-left: calc(50% - 50vw);
}
@mixin  full-bleed-rtl() {
    width: 100vw;
    margin-right: calc(50% - 50vw);
}

@mixin semi-bleed-xl-up() {
    margin-left: -10vw; // bleed
    margin-right: -10vw; 
}


/*
* styles
* To be defined
*/
// h1 
@mixin headline1() {
    font-size: 1.125rem;
    font-size: clamp(1.125rem, 0.95rem + 0.8750000000000001vw, 2rem);
    // font-weight: 900;
} 

/*
@mixin h1MarginBottom() {
    margin-bottom: 0.625rem;
    margin-bottom: clamp(0.625rem, 0.58rem + 0.22499999999999998vw, 0.85rem);
}
*/

$fontSmall: 0.875rem;
// figcation
@mixin captiontext() {
    font-size: $fontSmall;
    font-style: italic;
    line-height: 1.3;
} 
// blockquote
// eg [ cite cite-board ] AND 
// .column .text blockquote (where p:last child might be <cite>)
@mixin blockquote() {
    font-size: 1.25rem; 
    line-height: 1.5;
}

/*
@mixin headline-indent($bg) {
    padding: 1rem;
    background-color: $bg;
    border-radius:0.25rem; 
    margin-bottom: clamp(0.5rem, 0rem + 2.5vw, 3rem); // 0.5 @320 - 3 @1920
}
*/


// Dragging
@mixin grab-cursor {
    // cursor: url('https://www.google.com/intl/en_ALL/mapfiles/openhand.cur'), all-scroll;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
  }
  
  @mixin grabbing-cursor {
    // cursor: url('https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur'), all-scroll;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
  }
 