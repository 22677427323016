// section[data-variant="home-interviews"] {}

section[data-variant="home-interviews"] {
    position: relative;
    width: 100%; 
    overflow: hidden; 

    .section-inner {
    display: grid; 
    width: 100%;
    grid-gap: var(--gutter);
    grid-template-areas: "head"
                         "swip";
    // grid-template-rows: calc(100vh - 300px) 150px 150px;
    grid-template-columns: 100%; // NOTE SWIPER DOES NOT WORK with unnamed or fractioned columns

    .section-header {
        grid-area: head;
        // margin-bottom: var(--gutter);
        grid-column: 1 ;
    }
    .section-content {
        grid-area: swip;
        grid-column: 1 ;
    }
    .swiper-container {
        width: 100%;
        max-width: 100%;
        max-height: 100vh;
        // CSS Grid/Flexbox bug size workaround
        // @see https://github.com/kenwheeler/slick/issues/982
        min-height: 0;
        min-width: 0;
    }
}

    // grid-column: span 12;

    /*
    .section-content-inner {
        position: relative;
    width: 100%; 
    }

    .swiper-container {
        width: 100%;
        max-width: 100%;
        max-height: 100vh;
        // CSS Grid/Flexbox bug size workaround
        // @see https://github.com/kenwheeler/slick/issues/982
        min-height: 0;
        min-width: 0;
      }

      */
}

