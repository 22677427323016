.sidebar-widget {
    margin-bottom: 3rem;

    &[data-variant="anchors"]  {
        position: relative;
        background: #f3f3f3;
        border: 4px solid #fff;
        border-radius: 2px;
        box-shadow: 0 1px 2px hsla(0,0%,0%,0.05), 0 1px 4px hsla(0, 0%, 0%, 0.05), 0 2px 8px hsla(0, 0%, 0%, 0.05);
    }

    &[data-variant="randomBio"]  {
        .card .media-image .image {
            overflow: hidden;
        }
    }
}