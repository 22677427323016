// https://codepen.io/hexagoncircle/pen/YzZadLx

  /* Media Box styles */
  $mediaSpace: 0.5rem;
  $mediaColor: var(--color-dark);
  $mediaBgColor: var(--color-gray-light);
  // $mediaBgColor: var(--color-turquoise-light);
  
.media {
    /* Grid properties */
    display: grid;
    grid-template:
        [box-start] "tagline actions" [box-end] /
        [box-start] 1fr auto [box-end];

    > *,
    &::before {
        grid-area: box;
    }

    .copy {
      place-self: start;
    }
      
    .title {
      place-self: center;
    }
      
    .caption {
      grid-area: tagline;
      place-self: end start;
    }
      
    .actions {
      grid-area: actions;
      place-self: end;
    }

    /* Media Box styles */
    --space: #{$mediaSpace};
    --space-sm: calc(var(--space) / 2);
    --space-lg: calc(var(--space) * 4);
    --color: #{$mediaColor};
    --bg-color: #{$mediaBgColor};
  
    direction: var(--direction, ltr);
    position: relative;
    overflow: hidden;
    padding: var(--space-sm);
    color: var(--color, white);
    border-style: solid;
    border-color: var(--bg-color, var(--color-turquoise-light));
    // border-width: 0.3125rem; // 5px @ 320
    // border-width: clamp(0.3125rem, -0.25rem + 2.8125vw, 3.125rem);  // 50px @ 1920
    border-width: 5px; // 0.3125rem;
    border-width: clamp(5px, -1px + 1.875vw, 35px); // clamp(0.3125rem, -0.0625rem + 1.875vw, 2.1875rem);
  &::before {
    content: "";
    position: relative;
    background: black;
    
    z-index: -1;
    transition: opacity 200ms ease-out;
  }

  &-video {
      &::before {
        opacity: 0.4;
      }

      .video-container-inline {
        --w: 16;
        --h: 9;
        display: block;
        position: relative;
        padding-bottom: calc(100% / var(--w) * var(--h));
        // padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;
        width: 100%; 
      }

      // .video-container-inline iframe {
      // }
  }
  &-image {
      &::before {
        opacity: 0; // reverse logic to video -> fade to black on hover
      }
  }
  .image {
    overflow: hidden;
    width: 100%; 
  }
  img {
    position: relative;
    inline-size: 100%;
    width: 100%;
    // height: auto;
    height: 100%;
    object-fit: cover;
    object-position: var(--object-postion, 50% 50%); // fallback 50 50 / center center
    z-index: -2;
  }
  
  
  
  .title {
    padding: var(--space);
    font-size: 1.5rem;
    // font-family: var(--font-secondary);
    color: var(--bg-color);
    line-height: 1.2;
    text-align: center;

    user-select: none;
  }
  
  .title a {
    color: inherit;
    text-decoration: none;
  }
  
  .title a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .caption {
    padding: var(--space-sm) var(--space);
    // font-family: var(--font-secondary);
    // font-size: 0.9rem;
    @include captiontext();
    color: var(--bg-color); 
    // font-style: italic;
    // line-height: 1.2;
    user-select: none;
  }
  
  .actions {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    padding: var(--space-sm);
    gap: var(--space-sm);
  }
  
  .btn-media {
    flex: 1; // flex: 1 1 content; test old safari content means the basis should always fit the content
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    padding: var(--space);
    font-size: inherit;
    font-family: inherit;
    color: var(--color);
    background-color: var(--bg-color);
    border: none;
  }
  
  .btn-media:hover {
    filter: contrast(80%);
  }
  
  .btn-media:active {
    transform: translateY(1px);
  }
  
  [data-icon],
  svg {
    flex: 1 1 auto; 
    
    inline-size: 1em;
    fill: currentcolor;
  }
// }
}

// copy for reusage in regular templates 
// so we need to style it for .media && .text figure (which is slightly different to media, so you cannot reuse .media class on figure)
.media,
.text figure {
  /* Media Box styles */
  --space: #{$mediaSpace};
  --space-sm: calc(var(--space) / 2);
  --space-lg: calc(var(--space) * 4);
  --color: #{$mediaColor};
  --bg-color: #{$mediaBgColor};

  .copy {
    // padding: var(--space);
    color: var(--color);
    font-size: 0.75rem;
    letter-spacing: 0.05rem;
    // background-color: var(--bg-color);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    overflow: hidden;
  }
  .copy-indicator {
    display: flex;
    cursor: pointer;
    padding: var(--space);
    font-size: inherit;
    font-family: inherit;
    color: var(--color);
    background-color: var(--bg-color);
    border: none;

    // position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // height: 100%; 
    // background-color: var(--bg-color);
    
    z-index: z('content');

    display: flex;
    align-items: center;
  }
  .copy-text {
    display: flex;
    align-items: center;
    position: relative;
    line-height: 1;
    padding: var(--space);
    background-color: var(--bg-color);

    transform: translateX(-100%);
    transition: transform 200ms ease; 
    will-change: transform; 
  }
}


/* Transitions */
/* Note: We also added some addition .card elements here - in case media is wrapped within a .card */
.media {
  transition: border-color 200ms ease;
}
  
.media:hover {
  border-color: transparent;
}
  
.media .caption {
  opacity: 0;
}
  
.media .title,
.media .caption,
.media::before {
  transition: opacity 200ms ease;
}
  
.media:hover .title,
.media:hover .caption,
.media-video:hover::before {
  opacity: 0;
}
.media-image:hover::before,
.card:hover .media-image::before {
    opacity: 0.4;
}
  
.media:hover .caption,
.card:hover .media .caption {
   opacity: 1;
}

.media:hover .copy-text {
  transform: translateX(0);
}
  
.media img {
  transition: transform 200ms ease-out;
}
  
.media:hover img,
.card:hover .media img {
   transform: scale(1.025);
}


/* RTL */
// Default Media
.media[data-dir="rtl"] {

  // Reverse Media Grid 
  grid-template:
    [box-start] "actions tagline" [box-end] /
    [box-start] auto 1fr [box-end]; // gid template flipped around

  // copy top right 
  .copy {
      place-self: start end;
      flex-direction: row-reverse;
    }
  .copy-text {
      transform: translateX(100%);  
  }
  &:hover .copy-text {
      transform: translateX(0);
  } 
  
  // caption right
  .caption {
      direction: rtl;
      place-self: end end;
  }
}
