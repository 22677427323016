// https://bootcamp.uxdesign.cc/how-to-create-truly-fluid-typography-online-efe5dcf58a9c
// https://css-tricks.com/snippets/sass/viewport-sized-typography-minimum-maximum-sizes/

// usage
// @include responsive-font(5vw, 35px, 150px, 50px);
// 

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback), 
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
    $responsive-unitless: $responsive / ($responsive - $responsive + 1);
    $dimension: if(unit($responsive) == 'vh', 'height', 'width');
    $min-breakpoint: $min / $responsive-unitless * 100;
    
    @media (max-#{$dimension}: #{$min-breakpoint}) {
      font-size: $min;
    }
    
    @if $max {
      $max-breakpoint: $max / $responsive-unitless * 100;
      
      @media (min-#{$dimension}: #{$max-breakpoint}) {
        font-size: $max;
      }
    }
    
    @if $fallback {
      font-size: $fallback;
    }
    
    font-size: $responsive;
  }