// 
// Fluid
// https://royalfig.github.io/fluid-typography-calculator/
// 0.90625rem (14.5px) @320 - 1,125 (18px) @ 1920 
// 
body {
    font-size: 0.90625rem;
    font-size: clamp(0.90625rem, 0.8625rem + 0.21875000000000003vw, 1.125rem);
}
[dir="ltr"] body {
    font-family: var(--font-family-sansLatin); 
}
[dir="rtl"] body {
    font-family: var(--font-family-sansHebrew); 
}

/* 
* Globals - do finetuning in .text > 
*/
a {
    color: currentColor;
    text-decoration: none;
}

/*
strong, b {
    font-weight: 600;
}
*/
[dir="ltr"] {
    strong, b {
        font-family: var(--font-family-sansLatinBld); 
        font-weight: normal;
    }
    em, i {
        font-family: var(--font-family-sansLatinItalic); 
        font-weight: normal;
    }
    strong, b {
        em, i {
            font-family: var(--font-family-sansLatinBldItalic); 
            letter-spacing: -0.02em;
            // font-weight: normal;
        }
    }
}
[dir="rtl"] {
    strong, b {
        font-family: var(--font-family-sansHebrewBld); 
        font-weight: normal;
    }
    em, i {
        font-weight: normal;
        font-style: italic; // font-style: italic;
    }
    strong, b {
        em, i {
            font-family: inherit; 
            // font-weight: inherit;
        }
    }
}

small {
    font-size: $fontSmall;
    color: var(--color-text-grey);
}

ul.unstyled {
    margin: 0;
    padding: 0; 
    li {
        list-style-type: none;
        @include marginM();
    } 
}

/* 
* Headlines 
*/
h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}

h1, .h1 {
    @include headline1(); 
    line-height: 1.2;
}
h1:not(.brand), 
h2 {
    // add margin-bottom BUT NOT TO .h1/.h2 classe (eg on menu)
    @include headline1(); 
    @include marginS();
}
[dir="ltr"] {
    // change headlines to Condensed (ONLY ON LTR -> ON RTL use body font for all)
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: var(--font-family-headlineLatin);
    }
}
[dir="rtl"] {
    // change letter spacing on RTL to mimic condensed font on ltr (ONLY ON RTL -> ON LTR use headline font)
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        // letter-spacing: -0.05rem;
        font-family: var(--font-family-headlineHebrew);
    }
}

/* 
* Headers (might override headlines)
*/
.page-header,
.section-header {
    h1, h2, .h1, .h2 {
        color: var(--color-turquoise-dark); 
    }
}

/*
* Text
*/
.text {
line-height: 1.5em;
}
.text:not(.text-copy) a,
.hero-content a {
text-decoration: underline;
font-weight: 600;
transition: color 160ms ease;
    &:hover {
        color: var(--color-turquoise-dark); 
        text-decoration: none;
    }
}
.text :first-child {
margin-top: 0;
}
.text :last-child {
margin-bottom: 0;
}
.text p,
.text ul,
.text ol {
// margin-bottom: 1.5rem;
// margin-bottom: 2rem;
@include marginM(); 
}
.text ul,
.text ol {
margin-left: 1rem;
}
.text ul p,
.text ol p {
margin-bottom: 0;
}
.text ul > li {
list-style: disc;
}
.text ol > li {
list-style: decimal;
}
.text ul ol,
.text ul ul,
.text ol ul,
.text ol ol {
margin-bottom: 0;
}
.text hr {
margin: 6rem 0;
}
.text dt {
font-weight: 600;
}

// limit the text width until 992 px (all editor layouts)
.column .text {
    // limit max width up until 992
    @include media-query('mdmax') {
        max-width: 620px;
    }

    // spave above h2 after p tags
    p + h2 {
        margin-top: $space-l; 
        margin-top: $space-l-fluid;
    }

    // force full with ? TBD 
    figure img {
        max-width: initial;
        width: 100%;
    } 
}

// set an unstyled ul (used in some cases)
ul.unstyled {
    margin:0;
    padding: 0;
    li {
        list-style-type:none;
    }
}

// figure 
// note, this is similar, but not equal to .media element
// in reg templates, this gets used. its simpler and not that powerful, better suiting the layout editor
.block-image {
    display: grid;
    grid-template:
        [box-start] "box" [box-end]
        [caption-start] "caption" [caption-end] /
        [box-start] 1fr [caption-end];
    
    position: relative;
    overflow: hidden;
    margin: 3rem 0;

    // > *,
    > a, 
    > .img, 
    &::after {
        grid-area: box;
    }

    .img {
        display: block;
        position: relative;

        /*
        img {
            width: 100%;
            max-width: initial;
        }
        */
    }

    .copy {
        grid-area: box;
        place-self: start;
        z-index: 1;
    }
    figcaption,
    .img-caption {
        grid-area: caption;
        place-self: start;

        @include captiontext(); 
        padding-top: .75rem;
        // color: var(--color-text-grey);
    }

    &::after {
        content: "";
        position: relative;
        background: black;
        opacity: 0; // reverse logic to video -> fade to black on hover
        // z-index: -1;
        pointer-events: none;
        transition: opacity 200ms ease-out;
    }

    svg {
        inline-size: 1em;
        fill: currentcolor;
    }

    &:hover {
        &::after {
            opacity: 0.4; 
            // z-index: 1;
        }
        .copy-text {
            transform: translateX(0);
        }
    }
}

[dir="rtl"] {
    .block-image {
        .copy-text {
            transform: translateX(100%);  
        }
        &:hover {
            .copy-text {
                transform: translateX(0);
            }
        }
    }
}


hr {
border: 0;
background: currentColor;
height: 2px;
width: 1.5rem;
margin: 3rem auto;
}

.align-center {
text-align: center;
}

/* 
* Boards 
*/
.board-title {
    color: var(--color-turquoise-dark); 
    span, .headline {
        color: var(--color-dark); 
        display: block; 
    }
}

.text-board[data-variant="authors"],
.text-board .caption {
    @include captiontext(); 
    // developed by
    span {
        display: block;
        font-weight: 700;
    }
}

/* 
* Blockquote
*/
// Blockquote 
// .cite.cite-board  AND 
// .column .text blockquote (where p:last child might be <cite>)
/*
.column .text blockquote p:not(:last-child) {
    @include blockquote(); 
}
p + blockquote {
    margin-top: 4rem; 
}
*/


/*
* MISC COMPONENTS
*/ 

// goto (similar to .nav-links (see feature-swiper-board on home) but with a more global scope
// should be used in alle section headers that have a link  
.goto {

    &-link {

        display: block;
        line-height: 1;
        padding: 0.5rem 0; 
        color: var(--color-dark);
        text-decoration: none;
                
        transition: color 160ms ease; 
        will-change: color;

        &:hover, &:focus {
            color: var(--color-turquoise-dark);
            // opacity: 1;
        }

        svg {
            inline-size: 1em;
            fill: currentcolor;
        }
    }  
}