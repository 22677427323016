html {
    text-size-adjust: 100%; // experimental
    scroll-behavior: smooth;
}

/* Body */
body {
    line-height: 1.5; // line-height: 1.5;
    color: var(--color-text);
    background: var(--color-light);
}


/* Header */
#header {
    position: fixed;
    height: $mobileHeaderHeight; 
    z-index: z('header');
    // width: 100vw; 
    width: 100%; 
    transform: translateY(0);
    will-change: transform;
    transition: transform 0.2s ease; 

    // pointer events ! (deacitvate for header BUT enable for elements!
    pointer-events: none;
    .brand-link, .hamburger, .btn-language, #language-switcher {
        pointer-events: all;
    }

    @include media-query('lg') {
        // position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: $mobileHeaderHeight; 
    }
    // mobile only
    @include media-query('mdmax') {
        background-color: $frameColor;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    }

    /* RTL */
    // &[data-dir*='rtl'] {
    &[data-dir='rtl'] {
        @include media-query('lg') {
            left: auto;
            right: 0; 

            .brand {
                left: auto; // reset 
                right: calc($mobileHeaderHeight * -1); 
            }

            .brand-link {
                &:hover {
                    transform: translateX($mobileHeaderHeight * -1);
                }
            }
        }
    }
}
.header-inner {
    position: relative;
    height: 100%; 
    display: flex; 
    justify-content: space-between;
    align-items: center;

    @include media-query('lg') {
        flex-direction: column;
    }
}

/* Brand (Logo Tagline) */
.brand {
    font-size: 0.925rem !important;
    color: rgba(0,0,0,0.42); 
    
    @at-root .brand-inner {
        display: block;
        position: relative;

        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
        @include media-query('mdmax') {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
    }

    @include media-query('lg') {
        order: 2; 

        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        left: calc($mobileHeaderHeight * -1); 
        // margin-top: calc($mobileHeaderHeight * -1); 
    }
}
.brand-logo, .brand-tagline {
    display: block;
}
.brand-logo {
    height: calc($mobileHeaderHeight - 15px); 
    // height: $mobileHeaderHeight; 
    padding: 0 15px;  
    svg { 
       height: 100%;  
    }
    @include media-query('lg') {
        height:  calc($mobileHeaderHeight * 2); 
        width:  calc($mobileHeaderHeight * 2); 
        padding: 0;  
    }
}
.brand-tagline {
    font-size: 1.15rem;
    line-height: 1;
    letter-spacing: -.025em; 
    
    @include media-query('sm') {
        font-size: 1.75rem;
    }
    @include media-query('lg') {
        @include screen-reader-only(); 
    }
}
.brand-link {
    // brand-link == brand-inner ! 
    transition: transform 0.32s;
    @include media-query('lg') {
        &:hover {
            transform: translateX($mobileHeaderHeight);
        }
    }
}

/* Hamburger */
$hamburgerWidth: 40px; 
.hamburger {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    // padding: 0 !important; 

    // > span
    .hamburger-box {
        display: block;
        width: $hamburgerWidth; // overrides default
        position: relative;
    }

    // > span > span
    .hamburger-inner {
        left: auto; 
        right: 0; // on ltr
        width: calc($hamburgerWidth * 0.6);
        transition: width 120ms ease-out; 
        &:before {
            width: calc($hamburgerWidth * 0.8);
            right: 0; // on ltr
            left: auto; 
        }   
        &:after {
            width: $hamburgerWidth;
            right: 0; // on ltr
            left: auto; 
        }   
    }

    @include media-query('lg') {
        order: 1; 
        flex-direction: column-reverse;

        /*
        // sr only!!!
        .hamburger-label {
            // transform-origin: 0 0;
            transform: rotate(90deg);
            justify-content: flex-start;
            margin-top: 1.25rem;
        }
        */

        position: relative;
        top: $maxWidthFrame;
    }
    @include media-query('lgmax') {
        .hamburger-inner:before {
            top: -8px; 
        }
        .hamburger-inner:after {
            bottom: -8px; 
        }
    }

    //JS HOVER 
    &.is-hover {
        .hamburger-inner {
            width: $hamburgerWidth;
            &:before, &:after {
                width: $hamburgerWidth;
            }
        }
    }
    &.is-active {
        .hamburger-inner {
            width: $hamburgerWidth;
            &:before, &:after {
                width: $hamburgerWidth;
            }
        }
    }
}

/* Tools */
.tools {
    display: flex;
   
    
    @include media-query('lg') {
        height: 100px; 
        width: 100%; // width of header
        flex-direction: column;
        align-items: center;
    }

    &-top {
        align-items: center;

        > *:not(:last-child) {
            margin-right: 5px; 
        }

        .btn-language {
            height: 100%;
        }

        @include media-query('lg') {
            justify-content: flex-end;

            .btn-language {
                display: none!important;
            }
        }
        @include media-query('lgmax') {
            .btn-language {
                margin-right: 0.75rem; 
            }
            /* RTL */
            &[data-dir="rtl"] {
                .btn-language {
                    margin-right: 0; 
                    margin-left: 0.75rem; 
                }
            }
        }
    }
    
    &-bottom {
        display: none; 

        @include media-query('lg') {
            display: flex;
            justify-content: flex-start;
            order: 3; 
        }
    }
   
}

/* Navigation (Off Canvas) */
/*
// OFF CANVAS WHOLE NAV 
#navigation {
    position: fixed;
    left: 0;
    top: 0;
    transform: translate3d(0, -100vh, 0);
    background-color: bisque;     
    width: 100vw;
    height: 100vh;
    z-index: z('offcanvas');
    // https://cubic-bezier.com/#.48,.75,.85,.73
    // transition: transform .3s cubic-bezier(0, .52, 0, 1);
    transition: transform .8s cubic-bezier(.38,.74,.82,.75);

    @include media-query('lg') {
        // LTR
        transform: translate3d(-100vw, 0, 0);        
        // RTL 
        &[data-dir='rtl'] {
            transform: translate3d(100vw, 0, 0);
        }
    }

    // active state (JS)
    // Must come after Media Query!
    &.is-active {
        transform: translate3d(0, 0, 0);
    }
}
*/

// OFF CANVAS BG ONLY
#navigation {
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: z('nav');
    pointer-events: none;
}
#navigaton-bg {
    position: absolute;
    height: inherit; // 100%
    width: inherit; // 100%
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; 
    background-color: var(--color-turquoise);    
    opacity: 0;
    transform: translate3d(0, -100vh, 0);
    // https://cubic-bezier.com/#.48,.75,.85,.73
    // transition: transform .3s cubic-bezier(0, .52, 0, 1);
    // transition: transform .8s cubic-bezier(.38,.74,.82,.75), opacity .6s ease-in-out;
    transition: transform .5s ease-out, opacity .3s ease-out;
    will-change: transform, opacity;
}
.nav-inner {
    position: relative;
    // overflow-y: scroll; // make scrollable when active only
    // width: 100%;
    // padding: 3vw 12vw;
    // width: $maxWidthBoard; 
    width: inherit;
    overflow-x: hidden; // hide x scrollbar since child is 100vw 
    // height: 100%;
    
    // margin: 0 auto;  
    // padding: calc($mobileHeaderHeight * 1.5) calc($maxWidthFrame * 2);
    opacity: 0;

    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    // align-items: center;
    // align-items: flex-start; // make center on large


    transform: translate3d(0, -100vh, 0);
    will-change: transform, opacity;
    // transition: transform .8s cubic-bezier(.38,.74,.82,.75) .3s, opacity .6s ease-in-out .3s;
    transition: transform .7s ease-out, opacity .4s ease-out .2s;

    /*
    @include media-query('lg') {
        
        padding: calc($maxWidthFrame * 2) 12vw; 
    }
    */

   /*
   @media (min-height: 992px) and (min-width: 1216px) {
       // https://stackoverflow.com/questions/42550877/css-flex-vertically-centered-items-that-are-taller-than-the-viewport
       // HANDLE with care - if centered but to much content, you cannot scroll to the very top (some stuff on top might get hidden)
        // align-items: center; 
        align-items: center; 
   }
   */
}
.nav-inner-content {
    width: 100vw;
    max-height: 100%; // this makes it scrollable
    overflow-y: auto; // this makes it scrollable
    overflow-x: hidden; // hide x scrollbar since child is 100vw 
    padding: calc($mobileHeaderHeight * 1.5) calc($maxWidthFrame * 2);
    
    @include media-query('lg') {
        padding: calc($maxWidthFrame * 4) 12vw; 
    }
}

// state
#navigation[data-dir='ltr'] {
    #navigaton-bg {
        @include media-query('lg') {
            // LTR
            transform: translate3d(-100vw, 0, 0);
        }
    }
    .nav-inner {
        @include media-query('lg') {
            // LTR
            transform: translate3d(-100vw, 0, 0);
        }
    }
}
#navigation[data-dir='rtl'] {
    #navigaton-bg {
        @include media-query('lg') {      
            // RTL 
            transform: translate3d(100vw, 0, 0);
        }
    }
    .nav-inner {
        @include media-query('lg') {      
            // RTL 
            transform: translate3d(100vw, 0, 0);
        }
    }
}
#navigation.is-active {
    pointer-events: all;
    #navigaton-bg {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    .nav-inner {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}


// html element override
.navigation-active,
.modal-active {
    body {
        overflow: hidden; 
    }
}

// scroll down
// mobile only
.scroll-down {
    #header {
        @include media-query('mdmax') {
            transform: translateY(calc($mobileHeaderHeight * -1));
        }
    }
}



