/*
* 1 Full Screen
* FINISHED
*
* Shows text always below images till desktop kicks in, then positioning inside image. 
* For cook grid positioning refer to
* https://css-tricks.com/positioning-overlay-content-with-css-grid/
* This mostly makes sense, if the image has no caption!!! 
* If it has a caption, you must take care of caption and copy according to image fields set by user (this tends to which overload the design fastly)
* but with the grid positioning methods above, this might work out just well and lets items be positioned in a more friendly way
*/
$textBorderWidth: 0.3125rem;

.board-gallery-one[data-variant="full"] {
    grid-auto-rows: unset; // reset rows
    grid-template-rows: minmax(min-content, max-content) 1fr;
    grid-template-columns: 100%;

    @include media-query('md') { 
        // grid-template-rows: repeat(12, 1fr); // equals cols from .gallery-grid (base settings)

        grid-template-rows: unset; // reset
        grid-template-columns: unset; // reset
        
        // grid-template:"container";
        // grid-template-areas: "container";
        // grid-template-rows: 1fr; // need to set a row so everything stays in place for positioning within container! (see css tricks)
        // grid-template: "container" 1fr;
        grid-template: "container" minmax(50vh, max-content);
   
        place-items: center;
        // place-content: center;
        place-content: stretch;
        > * {
            grid-area: container; // one single container
            // max-width: 1000px;
          }
    }

    // mobile
    .board-figure-1 {
        grid-row: 1;
        grid-column: 1; 

        /*
        @include media-query('smmax') { 
            .image img {
                height: auto!important; // reset 100% height of .grid (base scss) and media conatainers and let real height determine the height of first grid row
                // min-height: 30vh; // set a min height just for small devices
                min-height: 100vw; // set a min height just for small devices
            }
        }
        */
    }
   
    .board-content {
        grid-column: 1; 
        grid-row: 2;
    }
    .board-content .text-board {
        max-width: initial; // reset board
    }

    // text positioning
    @include media-query('md') { 
        .board-figure-1 {
            // grid-row: 1; // need to reset mobile definitions to 1/1 for positioning & overflow to work properly
            // grid-column: 1; // need to reset mobile definitions to 1/1 for positioning & overflow to work properly 
            max-height: calc(100vh - ($mobileHeaderHeight * 2)); // 100vh - view padding top and bottom (80px) - we need this, so the image does not stretch beyoung viewport on wide screens
        }
        .board-content {
            grid-row: 1; // need to reset mobile definitions to 1/1 for positioning & overflow to work properly
            grid-column: 1; // need to reset mobile definitions to 1/1 for positioning & overflow to work properly
            
            // width: 60%;
            width: 50%; // fallback
            width: clamp(600px, 50vw, 800px);

            height: auto; 
            place-self: start right; // default top right

            padding-bottom: 0; // reset 

            // border: 3px solid var(--color-gray-light);
            border: $textBorderWidth solid var(--color-gray-light);
        }
        .text-board {
            // background-color: var(--color-light);
            background-color: rgba(243,243,243,0.92);
            margin: 3px; 
            
            // default is top right position 
            /* padding: 0 0 $boardHorizontalSpaceMin $boardHorizontalSpaceMin; // FALLBACK
            padding: 0 0 $boardHorizontalSpaceFluid $boardHorizontalSpaceFluid; // CLAMP 
            */         
            // global padding if border is set 
            padding: calc($boardHorizontalSpaceMin / 2) $boardHorizontalSpaceMin calc($boardHorizontalSpaceMin / 2) $boardHorizontalSpaceMin; // FALLBACK
            padding: calc($boardHorizontalSpaceFluid / 2) $boardHorizontalSpaceFluid calc($boardHorizontalSpaceFluid / 2) $boardHorizontalSpaceFluid; // CLAMP
        }
        &[data-text-position='br'] {
            .board-content {
                place-self: end right;
            }
        }
        &[data-text-position='bl'] {
            .board-content {
                place-self: end left;
            }
        }
        &[data-text-position='tl'] {
            .board-content {
                place-self: start left;
            }
        }
    }
}

/* Exceptions */
