$menuHoverColor: #22393a; // HOVER AND ACTIVE / CURRENT Color 18% Lightnes of turqoise dark == darker

.menu {
    li {
        list-style-type: none;
    }

    /*
    a:not(.cardlet-link):not(.h1) {
        padding: 1rem;
        display: block;
        // &[aria-current] {
            // text-decoration: underline;
        // }
    }
    */

    @at-root #{&}-primary {
        .menu-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex-wrap: wrap;
            
            &:not(:first-child) {
                // margin-top: 3rem;
                margin-top: $space-l;
                margin-top: $space-l-fluid;
            }

            &.is-active {
                .menu-item-header {
                    a, .btn-collapse {
                        // color: #cccccc; 
                        color: $menuHoverColor; 
                    }
                }
            }
        }

        .menu-item-header {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-end;
            

            // font-size: 1.125rem;
            // font-size: clamp(1.125rem, 0.75rem + 1.875vw, 3rem);
            @include responsive-font(3vw, 24px, 48px, 24px);
            line-height: 1;
    
            a {
                text-decoration: none;
                // 
            }
            a, .btn-collapse {
                color:var(--color-light);
                transition: color 160ms ease; 
            }

            &:hover {
                a, .btn-collapse {
                    // color: #cccccc; 
                    color: $menuHoverColor; 
                }
            }
        }

        // note: submenu == collapse! (grid cardlets container)
        .submenu {
            display: block;
            position: relative;
            width: 100%;

            
            padding-left: 0.5rem; 
            padding-right: 0.5rem; 
            @include media-query('lg') {
                padding-left: 1rem; 
                padding-right: 1rem; 
            }

            .cardlets {
                padding-top: $space-m;
                padding-top: $space-m-fluid;
            }
        }
    }

    @at-root #{&}-secondary {
        margin-top: $space-l;
        margin-top: $space-l-fluid;
        padding: 0;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap; 
        justify-content: flex-start;
        
            
        > li {
            list-style-type: none;
            padding: 0;
            
            &:not(:last-child) {
                margin-right: 1.5rem;
            }
        }

        a,
        .edit-cookie {
            text-decoration: none;
            // color:#22393a; // 18% Lightnes of turqoise dark == darker
            color:var(--color-light);
            transition: color 160ms ease;
            &[aria-current], &:hover {
                // color:#0d1617; // 7% Lightnes of turqoise dark == darker
                // color:#22393a; // 18% Lightnes of turqoise dark == darker
                color: $menuHoverColor; 
            }
        }

        @include media-query('xsmax') {
            flex-direction: column;

            a,
            .edit-cookie {
                display: block;
                padding: .25rem 0; 
            }
        }
    }

    @at-root #{&}-sidebar {
        .menu-item,
        .menu-divider {
            padding-left: 1rem; 
            padding-right: 1rem; 
        }
        .menu-divider {
            padding-top: 0.5rem; 
            padding-bottom: 0.5rem; 
            background-color: #e0e0e0; 
            @include captiontext(); 

            span {
                color: rgba(26,26,26,0.72); 
            }
        }

        .menu-item {
            display: block;
            position: relative;
            margin-top: 0.5rem; 
            margin-bottom: 0.5rem; 
            
            // transition active
            &.is-active::before, &:hover::before {
                height: 100%; 
                opacity: 1; 
            }
        }

        .menu-item::before {
            display: block;
            content: ' ';
            position: absolute;
            bottom: 0; 
            left: -4px; 
            width: 4px;
            height: 0; 
            background-color: var(--color-turquoise-dark);;
            opacity: 0; 
            transition: opacity 160ms ease, height 160ms ease-in-out; 
        }

        a {
            display: block;
            line-height: 1;
            padding: 0.325rem 0; 
            transition: color 160ms ease; 
            &:hover, &[aria-current] {
                color: var(--color-turquoise-dark);
            }
             
        }
    }
}

/* RTL */
[data-dir="rtl"] {
    .menu-secondary {
        > li {
            &:not(:last-child) {
                margin-right: 0rem;
                margin-left: 1.5rem;
            }
        }
    }

    .menu-sidebar {
        .menu-item::before {
            left: auto;
            right: -4px; 
        }
    }
}