/*
* Footer
*/
$footerBreakPointRow: 105em; 
$footerPad: calc($maxWidthFrame * 2);

#footer {
    position: relative;
    margin: 0 auto;
    width: 100%; 
    max-width: $maxWidthBoard; 
    // overflow: hidden; // breacrumbs cause full page to overflow -> currently solved in breadcrumb.scss

    .footer-inner {    
        padding-left: $footerPad;
        padding-right: $footerPad;
        padding-bottom: $footerPad;

        @include media-query('lg') {
            padding-left: calc($footerPad * 3);
            padding-right: calc($footerPad * 3);
        }
        @include media-query('xxl') {
            // padding-left: calc($footerPad * 6);
        }
    }

    /* Content Wrapper */
    .footer-content {
        border-top: 4px solid white;
        padding-top: calc($footerPad / 2);

        display: flex;
        flex-direction: column-reverse;

        // @include media-query('xxl') {
            // 1680px == 105em
        @media (min-width: $footerBreakPointRow) {   
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    /* Copy */
    .footer-content-copy {
        flex: 1; 
        // @include media-query('xxl') {
        @media (min-width: $footerBreakPointRow) {   
            flex: 1 1 auto; // no shrink 
            // width: 180px; 
            min-width:190px;
            // max-width: 250px;
            margin-right: 1rem; 
        }

        // @include media-query('lgmax') {
        @media (max-width: $footerBreakPointRow) {
            // add a second borer on small devices only
            border-top: 4px solid white;
            padding-top: calc($footerPad / 2);
        }
    }

    /* Sponsors */
    .footer-content-splogos {
        flex: 1; 

        
        @include media-query('xl') {
            flex: 1 1 auto; // grow & shrink

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        .splogos-widget {
            margin-bottom: 3rem;
            @include media-query('xl') {
                margin-bottom: 1rem;
            }
        }

        .splogos-widget:first-child {
            @include media-query('xl') {
                margin-right: 3rem;
            }
        }
    }

    /* Format */
    .text-copy {
        text-align: center;
        @media (min-width: $footerBreakPointRow) {   
            text-align: left; 
        }
    }
    .text-splogos {
        @include media-query('lgmax') {
            text-align: center; 
        }
    }
    .text-copy,
    .text-splogos {
        margin-bottom: 0.75rem;
       
        span,
        .footer-links {
            // display: block;
            @include captiontext(); 
           
        }
        .footer-links {
            padding-top: 0.5rem; 
            a,
            .edit-cookie {
                margin: 0; 
                padding: 0.25rem 0.5rem; 
            }
            // @include media-query('xxl') {
            @media (min-width: $footerBreakPointRow) {   
                padding-top: 0.25rem; 
                a,
                .edit-cookie {
                    display: block;
                    padding: 0.125rem 0; 
                }
            }
        }
        a {
            text-decoration: none;
            transition: color 160ms ease;

            &:hover {
                color: var(--color-turquoise-dark); 
            }
        }  
    }
    .text-copy {
        .footer-links {
            display: block;   
        }
    }

    /* RTL */
    &[data-dir="rtl"] {
        .footer-inner {  
            @include media-query('xxl') {
                padding-left: calc($footerPad * 3);
                padding-right: calc($footerPad * 6);
            }
        }
        .footer-content-copy { 
            @include media-query('xxl') {
                margin-right: 0; 
                margin-left: 3rem; 
            }
        }
        .footer-content-sponsors .sponsors-widget:first-child  {
            // @include media-query('xxl') {
      
            @include media-query('xl') { 
                margin-right: 0; 
                margin-left: 3rem;
            }
          
        }
        .text-copy {
            @media (min-width: $footerBreakPointRow) {   
                text-align: right; 
            }
        }
    }
}
