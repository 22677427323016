/*
* 4 Grid
* The two images grid makes the assumption, that the first picture is rather portrait and the second rather landscape. 
* So order matters in panel!
* Text Position default = right (always)
*/

.board-gallery-two[data-variant="grid"] {
    grid-auto-rows: unset; // reset rows
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-columns: 100%;

    padding-bottom: $boardContentBottomSpacer; // space end for nav
    
    // mobile
    .board-figure,
    .board-content  {
        grid-column: 1; 
    }
    .board-figure-1 {
        grid-row: 1;
    }
    .board-figure-2 {
        grid-row: 3;
    }

    .board-content {
        grid-row: 2;
        padding-bottom: 0; // reset
    }

    // desktop
    @include media-query('md') { 
        // grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
        // grid-template-rows: $mobileHeaderHeight 1fr minmax(min-content, 500px) 1fr $mobileHeaderHeight; // 5 rows spacer 1f minmax(for image) 1fr spacer
        // grid-template-rows: $mobileHeaderHeight minmax(min-content, max-content) minmax(min-content, 500px) $mobileHeaderHeight; 
        // grid-template-rows: $mobileHeaderHeight minmax(30vh, 400px) minmax(min-content, 500px);
        grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
        grid-template-columns: repeat(12, 1fr);

        .board-figure-1 {
            grid-column: 1 / 7; 
            grid-row: 1 / 2;
            max-height: 600px; // limit the max height of image, if there is a lot of content (mostly not)
        }
        .board-figure-2 {
            grid-column: 1 / 13;
            grid-row: 2;

            // max-height: 600px; // limit the max height of image, if there is a lot of content (mostly not)
        }
        .board-content {
            grid-column: 7 / 13;
            grid-row: 1 / 2;

            padding-top: $boardHorizontalSpaceMin; // FALLBACK
            padding-top: $boardHorizontalSpaceFluid; // CLAMP
            padding-left: $boardHorizontalSpaceMin; // FALLBACK
            padding-left: $boardHorizontalSpaceFluid; // CLAMP
        }

        // Text Position LEFT 
        &[data-text-position="left"] {
            .board-figure-1 {
                grid-column: 7 / 13;
            }
            // .board-figure-2 {}
            .board-content {
                grid-column: 1 / 7;

                padding-left: 0; // reset
                padding-right: $boardHorizontalSpaceMin; // FALLBACK
                padding-right: $boardHorizontalSpaceFluid; // CLAMP
            }
        }

        // RTL 
        // RTL Text Right
        &[data-text-position="right"][data-dir="rtl"] {
            .board-figure-1 {
                grid-column: 7 / 13; 
            }
            .board-content {
                grid-column: 1 / 7; 
            }
        }
        // RTL Text Left
        &[data-text-position="left"][data-dir="rtl"] {
            .board-figure-1 {
                grid-column: 1 / 7; 
            }
            .board-content {
                grid-column: 7 / 13; 
            }
        }
    }

    @include media-query('boardmin') {
        .board-figure-1 {
            grid-column: 1 / 6; 
            grid-row: 1 / 3;

            max-height: initial; // reset
        }
        .board-figure-2 {
            grid-column: 6 / 13;
            grid-row: 1 / 2;
        }
        .board-content {
            grid-column: 6 / 13;
            grid-row: 2;
        }

        .board-content .text-board {
            max-width: initial !important;
            width: 100%;
            columns:  320px 2;
            column-gap: 3rem;
        }

        // Text Position LEFT 
        &[data-text-position="left"] {
            .board-figure-1 {
                grid-column: 8 / 13;
            }
            .board-figure-2 {
                grid-column: 1 / 8;
            }
            .board-content {
                grid-column: 1 / 8;
            }
        }

        // RTL 
        // RTL Text Right
        &[data-text-position="right"][data-dir="rtl"] {
            
            .board-figure-1 {
                grid-column: 1 / 6; 
            }
            .board-figure-2 {
                grid-column: 6 / 13;
                grid-row: 1 / 2;
            }
            .board-content {
                grid-column: 6 / 13; 
            }
        }
        // RTL Text Left
        &[data-text-position="left"][data-dir="rtl"] {
            
            .board-figure-1 {
                grid-column: 8 / 13; 
            }
            .board-figure-2 {
                grid-column: 1 / 8;
                grid-row: 1 / 2;
            }
            .board-content {
                grid-column: 1 / 8; 
            }
        }
    } 
}
