/*
.transition-fade {
    transition: 0.4s;
    opacity: 1;
  }
  html.is-animating .transition-fade {
    opacity: 0;
  }
*/

[data-transition="transition-fade"] .board-wrapper {
    transition: 0.4s;
    opacity: 1;
}

html.is-animating [data-transition="transition-fade"] .board-wrapper {
    opacity: 0;
}