
// Language
.nav-language {
    position: relative;
    
    ul {
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        @include media-query('xsmax') {
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
    ul li {
        list-style-type: none;
    }
}