.splogos {
    margin: 0;
    padding: 0; 

    text-align: center;

    @include media-query('xl') {
        text-align: left;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.splogos-item {
    display: inline-block;
    list-style-type: none;
    
    @include media-query('xl') {
        display: block;
        // flex: 0 1 auto; 
    }
    // center all items below xl
    /*
    @include media-query('lgmax') {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    */
}

.splogos-link {
    display: block;
    padding: 0 15px; 

    transition: transform .25s ease-in-out;

    &:hover {
        transform:scale(1.15);
    }

    @include media-query('xl') {
        padding: 0 25px;  
    }
}

.splogos-img {
    // min-height:90px;
    max-height: 90px;
    width: auto; 
}

/* RTL */
[dir="rtl"] {
    .splogos {
        @include media-query('xl') {
            text-align: right;
        }
    }
}