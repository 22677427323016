/*
* 1 Banner
* FINISHED
*/
.board-gallery-two[data-variant="banner"] {
    grid-auto-rows: unset; // reset rows
    // grid-template-rows: 30vh 30vh minmax(min-content, max-content);
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-columns: 100%;

    padding-bottom: $boardContentBottomSpacer; // space end for nav
    
    // mobile
    .board-figure {
        grid-column: 1; 
    }
    .board-figure-1 {
        grid-row: 1;
    }
    .board-figure-2 {
        grid-row: 3;
    }

    .board-content {
        grid-column: 1; 
        grid-row: 2;

        padding-bottom: 0; // reset
    }

    // desktop
    @include media-query('md') { 
        // grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
        grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
        grid-template-columns: repeat(12, 1fr);
        
         // content
        .board-content {
            grid-row: 2;
        }
        // SIZE 
        &.size-6-6 {
            .board-figure {
                grid-row: 1;
                max-height: 60vh;
            }
            .board-figure-1 {
                grid-column: 1 / 7;
            } 
            .board-figure-2 {
                grid-column: 7 / 13;
            } 
            .board-content {
                grid-column: 1 / 13;
            }
       
        }
        &.size-4-8 {
            .board-figure-1 {
                grid-column: 1 / 7;
                grid-row: 1 / 3;

                max-height: 60vh;
            }
            .board-figure-2 {
                grid-column: 7 / 13;
                grid-row: 1 / 2;
            } 
            .board-content {
                grid-column: 7 / 13;
            }
        }
        &.size-8-4 {
            .board-figure-1 {
                grid-column: 1 / 7;
                grid-row: 1 / 2;
            }
            .board-figure-2 {
                grid-column: 7 / 13;
                grid-row: 1 / 3;

                max-height: 60vh;
            } 
            .board-content {
                grid-column: 1 / 7;
            }  
        }  

        .board-content .text-board {
            max-width: initial;
            width: 100%;
            columns:  360px 3;
            column-gap: 3rem;
        }
    }
    @include media-query('boardmin') { 
        // grid-template-rows: minmax(50vh, 60vh) minmax(min-content, max-content);
        grid-template-rows: minmax(45vh, 55vh) minmax(min-content, max-content);
        
        &.size-6-6, &.size-4-8, &.size-8-4 {
            .board-content {
                grid-column: 1 / 13;
                grid-row: 2 / 3;
            }
            .board-figure-1, .board-figure-2 {
                grid-row: 1 / 2;
                max-height: initial;
            }
        }

        &.size-4-8 {
            .board-figure-1 {
                grid-column: 1 /  5;
            }
            .board-figure-2 {
                grid-column: 5 /  13;
            } 
        }
        &.size-8-4 {
            .board-figure-1 {
                grid-column: 1 /  9;
            }
            .board-figure-2 {
                grid-column: 9 /  13;
            }    
        }  
        .board-content {
            // grid-column: 1 / 10;
            // grid-row: 9 / 12;
            grid-column: 1 / 13;

            padding-top: $boardHorizontalSpaceMin; // FALLBACK
            padding-top: $boardHorizontalSpaceFluid; // CLAMP

            // padding-bottom: $boardContentBottomSpacer; 
        }
    }
}
