/*
* 3 Collage
* Finished
*
* Has Two Modes 
* 3.1 Overlay (Default) with text positioning (left, right, bottom) by user 
* 3.2 Overlap (2nd image breaks out overlappin) with auto-text positioning by CSS according to image position 
*/

// 3.1 Overlay 
// 3.2 Overlap
// Beahaviour is identical until XL (Boardmin)
.board-gallery-two[data-variant="collage"][data-collage="overlay"],
.board-gallery-two[data-variant="collage"][data-collage="overlap"] {
    grid-auto-rows: unset; // reset rows
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-columns: 100%;
    padding-bottom: $boardContentBottomSpacer; // space end for nav

    .board-figure-1 {
        grid-column: 1; 
        grid-row: 1;
    }
    .board-figure-2 {
        grid-column: 1; 
        grid-row: 3;
    }
    .board-content {
        grid-column: 1; 
        grid-row: 2;

        padding: 0; // reset
    }

    @include media-query('md') {
        grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
        grid-template-columns: repeat(12, 1fr);

        .board-figure-1 {
            grid-column: 1 / 13;
        }
        .board-figure-2 {
            grid-column: 1 / 7;
            grid-row: 2;

            max-height: 600px; // limit the max height of image, if there is a lot of content (mostly not)
        }
        .board-content {
            grid-column: 7 / 13;
            grid-row: 2;

            padding-top: $boardHorizontalSpaceMin; // FALLBACK
            padding-top: $boardHorizontalSpaceFluid; // CLAMP
            padding-left: $boardHorizontalSpaceMin; // FALLBACK
            padding-left: $boardHorizontalSpaceFluid; // CLAMP
        }
    }
}

// 3.1 Overlay 
.board-gallery-two[data-variant="collage"][data-collage="overlay"] {
    @include media-query('boardmin') {
        grid-template-rows: minmax(130px, 15vh) minmax(130px, 15vh) minmax(130px, 15vh) minmax(130px, 15vh) minmax(min-content, max-content);

        .board-content {
            grid-column: 1 / 13;
            grid-row: 5 / 6;

            padding-left: 0;
        }
        .board-figure-1 { 
            grid-column: 1 / 13;
            grid-row: 1 / 5;

        }

        &[data-img-position="tr"] {
            .board-figure-2 { 
                grid-column: 8 / 13; 
                grid-row: 1 / 4;
            }
        } 
        &[data-img-position="tl"] {
            .board-figure-2 { 
                grid-column: 1 / 6; 
                grid-row: 1 / 4;
            }
        }
        &[data-img-position="bl"] {
            .board-figure-2 { 
                grid-column: 1 / 6; 
                grid-row: 2 / 5;
            }
        }
        &[data-img-position="br"] {
            .board-figure-2 { 
                grid-column: 8 / 13; 
                grid-row: 2 / 5;
            }
        }
    }
    @include media-query('boardbetween') {
        grid-template-rows: minmax(150px, 18vh) minmax(150px, 18vh) minmax(150px, 18vh) minmax(150px, 18vh);
        grid-template-columns: repeat(4, 1fr); 

        padding-bottom: 0;

        .board-content {
            padding-top: 0; // Reset

            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        // RIGHT TEXT (DEFAULT)
        &[data-text-position="right"] {
            .board-content {
                grid-column: 4 / 5;
                grid-row: 1 / 5;

                padding-left: $boardHorizontalSpaceMin; // FALLBACK
                padding-left: $boardHorizontalSpaceFluid; // CLAMP
            }
            .board-figure-1 { 
                grid-column: 1 / 4;
                grid-row: 1 / 5;
    
            }
            &[data-img-position="tr"] {
                .board-figure-2 { 
                    grid-column: 3 / 4; 
                    grid-row: 1 / 4;
                }
            } 
            &[data-img-position="tl"] {
                .board-figure-2 { 
                    grid-column: 1 / 2; 
                    grid-row: 1 / 4;
                }
            }
            &[data-img-position="bl"] {
                .board-figure-2 { 
                    grid-column: 1 / 2; 
                    grid-row: 2 / 5;
                    min-height: 100%; // either 100% oder fallback to grid-row 3 / 5 instead of 2 / 5 (image gets smaller in height then)
                }
            }
            &[data-img-position="br"] {
                .board-figure-2 { 
                    grid-column: 3 / 4; 
                    grid-row: 2 / 5;
                    min-height: 100%; // either 100% oder fallback to grid-row 3 / 5 instead of 2 / 5 (image gets smaller in height then)
                }
            }
        }

        // LEFT TEXT
        &[data-text-position="left"] {
            .board-content {
                grid-column: 1 / 2;
                grid-row: 1 / 5;

                padding-right: $boardHorizontalSpaceMin; // FALLBACK
                padding-right: $boardHorizontalSpaceFluid; // CLAMP
            }
            .board-figure-1 { 
                grid-column: 2 / 5;
                grid-row: 1 / 5;
    
            }
            &[data-img-position="tr"] {
                .board-figure-2 { 
                    grid-column: 4 / 5; 
                    grid-row: 1 / 4;
                }
            } 
            &[data-img-position="tl"] {
                .board-figure-2 { 
                    grid-column: 2 / 3; 
                    grid-row: 1 / 4;
                }
            }
            &[data-img-position="bl"] {
                .board-figure-2 { 
                    grid-column: 2 / 3; 
                    grid-row: 2 / 5;
                    min-height: 100%; // either 100% oder fallback to grid-row 3 / 5 instead of 2 / 5 (image gets smaller in height then)
                }
            }
            &[data-img-position="br"] {
                .board-figure-2 { 
                    grid-column: 4 / 5; 
                    grid-row: 2 / 5;
                    min-height: 100%; // either 100% oder fallback to grid-row 3 / 5 instead of 2 / 5 (image gets smaller in height then)
                }
            }
        }
    }
    /* height exception */
    // 1.584 = boardbetween and MAX height 768
    @media only screen and (max-height: 768px) and (min-width: 1.584px) { 
        padding-bottom: $boardContentBottomSpacer; // add spacer for scroll - case is very unlikely
    }
}

// 3.2 Overlap
.board-gallery-two[data-variant="collage"][data-collage="overlap"] {
    @include media-query('boardmin') {
        grid-template-rows: minmax(130px, 15vh) minmax(130px, 15vh) minmax(130px, 15vh) minmax(130px, 15vh) minmax(130px, 15vh) 1fr;

        // Equal Spaces 

        .board-content {
            padding-top: $boardHorizontalSpaceMin; // FALLBACK
            padding-top: $boardHorizontalSpaceFluid; // CLAMP
        }


        // Top Right Image
        &[data-img-position="tr"] {
            .board-content {
                grid-column: 9 / 13;
                grid-row: 4 / 7;

                padding-right: 0;
                padding-left: $boardHorizontalSpaceMin; // FALLBACK
                padding-left: $boardHorizontalSpaceFluid; // CLAMP
            }
            .board-figure-1 { 
                grid-column: 1 / 9;
                grid-row: 2 / 6;

            }
            .board-figure-2 { 
                grid-column: 7 / 13; 
                grid-row: 1 / 4;
            }
        } 

        // Top Left Image
        &[data-img-position="tl"] {
            .board-content {
                grid-column: 1 / 5;
                grid-row: 4 / 7;

                padding-left: 0;
                padding-right: $boardHorizontalSpaceMin; // FALLBACK
                padding-right: $boardHorizontalSpaceFluid; // CLAMP
            }
            .board-figure-1 { 
                grid-column: 5 / 13;
                grid-row: 2 / 6;

            }
            .board-figure-2 { 
                grid-column: 1 / 7; 
                grid-row: 1 / 4;
            }
        }

        // Bottom Left Image
        &[data-img-position="bl"] {
            .board-content {
                grid-column: 7 / 13;
                grid-row: 4 / 7;

                padding-right: 0;
                padding-left: $boardHorizontalSpaceMin; // FALLBACK
                padding-left: $boardHorizontalSpaceFluid; // CLAMP
            }
            .board-figure-1 { 
                grid-column: 5 / 13;
                grid-row: 1 / 4;

            }
            .board-figure-2 { 
                grid-column: 1 / 7; 
                // grid-row: 2 / 5;
                grid-row: 2 / 6;
            }
        }

        // Bottom Right Image
        &[data-img-position="br"] {
            .board-content {
                grid-column: 1 / 7;
                grid-row: 4 / 7;
                
                padding-left: 0;
                padding-right: $boardHorizontalSpaceMin; // FALLBACK
                padding-right: $boardHorizontalSpaceFluid; // CLAMP
            }
            .board-figure-1 { 
                grid-column: 1 / 9;
                grid-row: 1 / 4;

            }
            .board-figure-2 { 
                grid-column: 7 / 13; 
                // grid-row: 2 / 5;
                grid-row: 2 / 6;
            }
        }
    }


    @include media-query('boardbetween') {

        // Top Right Image
        &[data-img-position="tr"] {
            .board-figure-2 { 
                grid-column: 8 / 13; 
            }
        } 

        // Top Left Image
        &[data-img-position="tl"] {
            .board-figure-2 { 
                grid-column: 1 / 6; 
            }
        }

        // Bottom Left Image
        &[data-img-position="bl"] {
            .board-content {
                grid-column: 6 / 13;
                grid-row: 5/7;
            }
            .board-figure-1 { 
                grid-row: 1/5;
            }
            .board-figure-2 { 
                grid-column: 1 / 6;
                grid-row: 2 / 6;
                max-height: initial;
            } 
        }
        
        // Bottom Right Image
        &[data-img-position="br"] {
            .board-content {
                grid-column: 1 / 8;
                grid-row: 5/7;
            }
            .board-figure-1 { 
                grid-row: 1/5;
            }
            .board-figure-2 { 
                grid-column: 8 / 13; 
                grid-row: 2 / 6;
                max-height: initial;
            }
        }
    }
}


/*
* Exceptions 
* .media Element
*/

// Treat the border of overlaying images differently by its position
// Do this, when Collage kicks in (see media queries: if you change above, you must also change here!)
$figure2borderWidth: 0.3125rem; // 5px @ 320

@include media-query('boardmin') { 
  .board[data-variant="collage"] {  
    .board-figure-2, .board-figure-3, .board-figure-4 {
      &.media {
        position: relative;
        z-index: 3; // always bring secondary images up front
      }
    }
  }
  .board[data-variant="collage"][data-collage="overlay"] { 
    // Border Width By position
    &[data-img-position="tr"] {
      .board-figure-2, .board-figure-3, .board-figure-4 {
        &.media {
          border-left-width: $figure2borderWidth; // 5px @ 320
          border-bottom-width: $figure2borderWidth; 
        }
      }
    } 
    &[data-img-position="br"] {
      .board-figure-2, .board-figure-3, .board-figure-4 {
        &.media {
          border-left-width: $figure2borderWidth; // 5px @ 320
          border-top-width: $figure2borderWidth; 
        }
      }
    } 
    &[data-img-position="bl"] {
      .board-figure-2, .board-figure-3, .board-figure-4 {
        &.media {
          border-right-width: $figure2borderWidth; // 5px @ 320
          border-top-width: $figure2borderWidth; 
        }
      }
    }
    &[data-img-position="tl"] {
      .board-figure-2, .board-figure-3, .board-figure-4 {
        &.media {
          border-right-width: $figure2borderWidth; // 5px @ 320
          border-bottom-width: $figure2borderWidth; 
        }
      }
    }  
  }

  .board[data-variant="collage"][data-collage="overlap"] { 
    .board-figure-2, .board-figure-3, .board-figure-4 {
      &.media {
        border-width: $figure2borderWidth;
      }
    }
  }
}
