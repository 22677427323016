.prevnext-wrap {
    position: fixed;
    bottom: $maxWidthFrame;
    // left: $maxWidthFrame;
    // right: $maxWidthFrame;
    left: 0;
    right: 0;
    width: 100%; 
    height: 44px; // limit the max height . experimentell for safari - 36 inner + 8px spacing (half of frameborder -> see padding-bottom)
    padding-bottom: calc($maxWidthFrame / 2);  
    z-index: z('frame-above');

    opacity: 1;
    will-change: opacity;
    transition: opacity 200ms ease-out;
}

.prevnext-wrap-inner {
    position: relative;
    margin-left: auto;
    margin-right: auto; 
    
    // min-width: 320px; 

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.prevnext {
    flex: 1 1 content; // try to fix the height problem in safari

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    padding: 0.25rem 0.75rem;
    border-radius: 25px;
    background-color: var(--color-turquoise);
    // background-color: var(--color-dark);
    color: var(--color-light); 

    
}

// Global Stlyes for Direct Childs 
// > button == index,
// > span =  start end indicator
// > .prevnext-btn[data-variant="start"] = LAST PAGE Exception -> shows back to start span
.prevnext button,
.prevnext > span,
.prevnext-btn[data-variant="start"] {
    flex: 1 1 auto; 
    display: inline-block;

    color: inherit; 
    text-decoration: none; 
    line-height: 1;
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
}

.prevnext-btn-index {
    padding: 0 0.75rem;  
    outline: none;
    appearance: none;
    // color: red;
    background: none;
    border: 0px;
    // font-weight: bold;
    cursor: pointer;
    // pointer-events: all;
    line-height: 1;

    &:hover {
        filter: contrast(80%);
    }

    // Mobile title span (inline)
    .index-title[data-variant="mobile"] {
        @include media-query('md') {
            display: none;
            visibility: hidden;
        }
    }
    // Desktio title span (inline)
    .index-title[data-variant="desktop"] {
        display: none;
        @include media-query('md') {
            display: inline;
        }
    }
}
    
.prevnext-btn {
    flex: 1 1 content; 
    display: flex;

    cursor: pointer;
    padding: 0 0.25rem;
    font-size: inherit;
    font-family: inherit;
    color: var(--color-light);
    background-color: transparent;
    border: none;
    
    &[data-variant="prev"] {
        &:active {
            transform: translateX(-1px);
        }
    }
    &[data-variant="next"] {
        &:active {
            transform: translateX(1px);
        }
    }

    &:hover {
        filter: contrast(80%);
    }
   
    svg {
        inline-size: 1.5em;
        fill: currentcolor !important;
    }
}

/*Exceptions */
.tpl-board_cite, 
[data-template="board_cite"] {
    .prevnext {
        box-shadow: rgba(0, 0, 0, 0.50) 0px 1px 2px 0px;
    }
}


/* RTL */
.prevnext-wrap[data-dir="rtl"] {
    .prevnext-btn svg {
        transform: rotate(180deg);
    }
}

/* JS GLOBAL STATE */
.navigation-active,
.modal-active {
    .prevnext-wrap {
        opacity: 0;
        pointer-events: none;
    }
}