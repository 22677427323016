/*
* Exceptions 
* .media Element
*/

// limit max caption width on some templates
.board[data-variant="full"],
.board[data-variant="collage"] {
  .board-figure-1 {
    &.media .caption {
      max-width: 55%;
    }
  }
}

/* 
* CHANGE Default Positioning of ".media" elements
// All are influenced by outside conditions -  either board settings OR RTL
// Note: Default positioning of Board elements is always TOP RIGHT
// Note: Default positioning of media caption is Bottom Left, of copyright is Top left, of Actions is Bottom Right 
// Since the media element works with named grid lines, we dont have to set everything explicitly, but handle all cases, where Images Or Text overlaps: 
// Overlapping occurs in boards: FULL, Two Images Collage
// 
*/
// Setup some Helper MIXINS
// create some NON-Default mixins first for alternate positioning of media element
// needed, when board text is repositioned AS WELL AS on RTL Overrides below (multiple cases here) 
@mixin copyTopRight() {
    .copy {
      place-self: start end;
      flex-direction: row-reverse;
    }
    .copy-text {
      transform: translateX(100%);  
    }
    &:hover .copy-text {
        transform: translateX(0);
    }
  }
  @mixin copyTopleft() {
    .copy {
      place-self: start start;
      flex-direction: row;
    }
    .copy-text {
      transform: translateX(-100%);  
    }
    &:hover .copy-text {
        transform: translateX(0);
    }
  }
  @mixin actionsTop() {
    .actions {
      place-self: start;
    }
  }
  @mixin captionTopRight() {
    .caption {
      grid-area: actions;
      place-self: start end;
    }
  }
  
  @mixin reverseMediaGrid() {
    grid-template:
      [box-start] "actions tagline" [box-end] /
      [box-start] auto 1fr [box-end]; // gid template flipped around
  }
  // END Helper MIXINS
  
  // Begin Handle Edge Cases by data attributes
  /* Repostion Elements (NON-RTL - only LTR) */
  // reposition elements according to gallery layouts 
  // text position of board default is top right AND image positin of collage is top right 
  .board[data-text-position="tl"],
  .board[data-img-position="tl"] .board-figure-1 {
    @include copyTopRight(); 
  }
  .board[data-text-position="br"],
  .board[data-img-position="br"] .board-figure-1 {
    @include actionsTop();
  }
  
  .board[data-text-position="bl"],
  .board[data-img-position="bl"] .board-figure-1 {
    @include captionTopRight();
  }
  
 
  // Repostion Elements by Panel user (in Full Screen Mode)
  // Text placed over box (see eg template gallery one "full screen")
  // note: ".caption" is not rendered in .media, but in board-text ( to simplify things ) - you dont need to worry about caption here
  // by text position (full screen)
  .board[data-text-position="tr"],
  .board[data-text-position="tl"] {
    // this is also the default behaviour if nothing is set by panel user
    .media[data-dir="rtl"] {
      .copy {
        place-self: end end;
      }
    }
  }
  .board[data-text-position="bl"] {
    .media[data-dir="rtl"] {
      @include actionsTop();
    }
  }
  
  // By image postion (collage)
  // only figure 1 is affected
  .board[data-img-position="br"] {
    .media[data-dir="rtl"].board-figure-1 {
      .caption {
        grid-area: box;
        place-self: end start;
      }
    }
  }
  .board[data-img-position="bl"] {
    .media[data-dir="rtl"].board-figure-1 {
      .actions {
        grid-area: box;
        place-self: end end;
      }
    }
  }
  .board[data-img-position="tr"] {
    .media[data-dir="rtl"].board-figure-1 {
      @include copyTopleft(); 
    }
  }
  
  
