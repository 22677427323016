/*
* Banner - 4 Images
* FINISHED
*/

.board-gallery-four[data-variant="banner"] {
    grid-auto-rows: unset; // reset rows
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-columns: 100%;

    padding-bottom: $boardContentBottomSpacer;

    // mobile
    .board-figure {
        grid-column: 1; 
    }
    .board-figure-1 {
        grid-row: 1;
    }
    .board-figure-2 {
        grid-row: 3;
    }
    .board-figure-3 {
        grid-row: 4;
    }
    .board-figure-4 {
        grid-row: 5;
    }

    .board-content {
        grid-column: 1; 
        grid-row: 2;

        padding: 0; // reset
    }

    // desktop
    @include media-query('md') { 
        grid-template-rows: minmax(25vh, 400px) minmax(25vh, 400px) minmax(min-content, max-content);
        grid-template-columns: repeat(12, 1fr);

        .board-figure-1 {
            grid-column: 1 / 7; // 13
            grid-row: 1;
        } 
        .board-figure-2 {
            grid-column: 7 / 13;
            grid-row: 1;
        } 
        .board-figure-3 {
            grid-column: 1 / 7;
            grid-row: 2;
        } 
        .board-figure-4 {
            grid-column: 7 /  13;
            grid-row:  2;
        } 
        .board-content {
            grid-column: 1 / 13; 
            grid-row: 3;
        }
        .board-content .text-board {
            max-width: initial;
            width: 100%;
            columns:  360px 3;
            column-gap: 3rem;
        }
    }
    @include media-query('boardmin') { 
        grid-template-rows: minmax(25vh, 350px) minmax(25vh, 350px) minmax(min-content, max-content);
        .board-figure-1 {
            grid-column: 1 / 9; // 13
            grid-row: 1 / 3;
        } 
        .board-figure-2 {
            grid-column: 9 / 13;
            grid-row: 1;
        } 
        .board-figure-3 {
            grid-column: 9 /  13;
            grid-row: 2;
        } 
        .board-figure-4 {
            grid-column: 1 / 5;
            grid-row:  3;
        } 
        .board-content {
            grid-column: 5 / 13; 
            grid-row: 3;

            padding-top: $boardHorizontalSpaceMin; 
            padding-left: $boardHorizontalSpaceMin; // FALLBACK
            padding-left: $boardHorizontalSpaceFluid; // CLAMP
        }

       /* RTL */
       &[data-dir="rtl"] {
            .board-content {
                padding-left: 0;
                padding-right: $boardHorizontalSpaceMin; // FALLBACK
                padding-right: $boardHorizontalSpaceFluid; // CLAMP
            }
        }
    }
    @include media-query('boardbetween') { 
        // grid-template-rows:$mobileHeaderHeight 1fr minmax(40vh, 600px) minmax(min-content, max-content) 1fr $mobileHeaderHeight;
        grid-template-rows: minmax(45vh, 55vh) minmax(min-content, max-content);
        .board-figure-1, .board-figure-2, .board-figure-3, .board-figure-4 { 
            grid-row: 1 / 2;
        }
        .board-figure-1 {
            grid-column: 1 / 4; // 13
        } 
        .board-figure-2 {
            grid-column: 4 / 7;
        } 
        .board-figure-3 {
            grid-column: 7 /  10;
        } 
        .board-figure-4 {
            grid-column: 10 / 13;
        } 
        .board-content {
            grid-column: 1 / 13; 
            grid-row: 2 / 3;

            padding-left: 0; // Reset
            padding-right: 0;
        }
        
        /* RTL */
        &[data-dir="rtl"] {
            .board-content {
                padding-left: 0; // Reset
                padding-right: 0;
            }
        }
    }
}
