/*
* 2 Grid (default Mode is 1 Portrait 2 Small -> portait_sm_sm )
* Default Text Position is (always) "right"
*
* FINSHED
*/
.board-gallery-three[data-variant="grid"] {
    grid-auto-rows: unset; // reset rows
    // grid-template-rows: 60vh 50vh 50vh minmax(min-content, max-content);
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-columns: 100%;

    padding-bottom: $boardContentBottomSpacer; // space end for nav
    
    // mobile
    .board-figure {
        grid-column: 1; 
    }
    .board-figure-1 {
        grid-row: 1;
    }
    .board-figure-2 {
        grid-row: 3;
    }
    .board-figure-3 {
        grid-row: 4;
    }

    .board-content {
        grid-column: 1; 
        grid-row: 2;

        padding-bottom: 0; // reset 
    }
    @include media-query('noboardmax') { 
        .board-content .text-board {
            max-width: initial !important;
            width: 100%;
            columns:  280px 2;
            column-gap: 3rem;
        }
    }

    // desktop
    @include media-query('md') { 
        // grid-template-rows: minmax(30vh, 300px) minmax(30vh, 300px) minmax(min-content, max-content); // $mobileHeaderHeight 
        grid-template-rows: minmax(35vh, 300px) minmax(35vh, 300px) minmax(min-content, max-content); 
        grid-template-columns: repeat(12, 1fr);  

        .board-figure-1 {
            grid-column: 1 / 7; // 13
            grid-row: 1 / 3;
        } 
        .board-figure-2 {
            grid-column: 7 /  13;
            grid-row: 1;
        } 
        .board-figure-3 {
            grid-column: 7 /  13;
            grid-row: 2;
        } 
        .board-content {
            grid-column: 1 /  13;
            grid-row: 3;
            
            // padding-top: $boardHorizontalSpaceMin; // FALLBACK
            // padding-top: $boardHorizontalSpaceFluid; // CLAMP
        }
    }
    @include media-query('boardmin') {
        // grid-template-rows:  $mobileHeaderHeight minmax(33vh, 400px) minmax(33vh, 400px) $mobileHeaderHeight  ;
        grid-template-rows: 3vh minmax(35vh, 36vh) minmax(35vh, 36vh) 3vh;
        
        padding-bottom: 0; // reset

        .board-figure-1 {
            grid-column: 1 / 5; // 13
            grid-row: 2 / 4;
        } 
        .board-figure-2 {
            grid-column: 5 /  9;
            grid-row: 2;
        } 
        .board-figure-3 {
            grid-column: 5 /  9;
            grid-row: 3;
        } 
        .board-content {
            grid-column: 9 / 13; 
            grid-row: 1 / 5;

            padding-top: 0; // reset
            padding-left: $boardHorizontalSpaceMin; // FALLBACK
            padding-left: $boardHorizontalSpaceFluid; // CLAMP
            padding-bottom: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;

        }

        /* Text Left */
        &[data-text-position='left'] {
            .board-figure-1 {
                grid-column: 5 / 9;
            }
            .board-figure-2, .board-figure-3 {
                grid-column: 9 / 13;
            }
            .board-content {
                grid-column: 1 / 5; 

                padding-left: 0;
                padding-right: $boardHorizontalSpaceMin; // FALLBACK
                padding-right: $boardHorizontalSpaceFluid; // CLAMP
    
            }
        }

        /* RTL */
        /* Text Right */
        &[data-text-position="right"][data-dir="rtl"] {
            
            .board-figure-1 {
                grid-column: 5 / 9; // 13
            } 
            .board-figure-2 {
                grid-column: 9 / 13;
            } 
            .board-figure-3 {
                grid-column: 9 / 13;
            } 
            .board-content {
                grid-column: 1 / 5; 

                padding-right: 0;
                padding-left: $boardHorizontalSpaceMin; // FALLBACK
                padding-left: $boardHorizontalSpaceFluid; // CLAMP
            }
        }
        /* Text Left*/
        &[data-text-position="left"][data-dir="rtl"] {
            
            .board-figure-1 {
                grid-column: 1 / 5; // 13
            } 
            .board-figure-2 {
                grid-column: 5 /  9;
            } 
            .board-figure-3 {
                grid-column: 5 /  9;
            } 
            .board-content {
                grid-column: 9 / 13; 

                padding-left: 0;
                padding-right: $boardHorizontalSpaceMin; // FALLBACK
                padding-right: $boardHorizontalSpaceFluid; // CLAMP
            }
        }
    }
    @include media-query('boardbetween') { 
        // grid-template-rows: 3vh minmax(20vh, 33vh) minmax(20vh, 33vh) 3vh;
        grid-template-rows: 3vh minmax(35vh, 38vh) minmax(35vh, 38vh) 3vh;
        .board-figure-1 {
            grid-column: 1 / 6;
            grid-row: 1 / 5;
        }
        .board-figure-2 {
            grid-column: 6 /  9;
            grid-row: 1 / 3;
        } 
        .board-figure-3 {
            grid-column: 6 /  9;
            grid-row: 3 / 5;
        } 
        .board-content {
            grid-column: 9 / 13;
        }

        .board-content .text-board {
            max-width: 44ch;
        }

         /* Text Left */
         &[data-text-position='left'] {
            .board-figure-1 {
                grid-column: 5 / 10;
            }
            .board-figure-2, .board-figure-3 {
                grid-column: 10 / 13;
            }
        }

         /* RTL */
        /* Text Right */
        &[data-text-position="right"][data-dir="rtl"] {
            
            .board-figure-1 {
                grid-column: 4 / 9; 
            } 
            .board-content {
                grid-column: 1 / 4; 
            }
        }
        /* Text Left*/
        &[data-text-position="left"][data-dir="rtl"] {
            .board-figure-1 {
                grid-column: 1 / 6;
            } 
            .board-figure-2 {
                grid-column: 6 /  9;
            } 
            .board-figure-3 {
                grid-column: 6 /  9;
            } 
            .board-content {
                grid-column: 9 / 13; 
            } 
        }
    }

    

    /* height exception */
    // 1216 = boardmin = xl and MAX height 768
    @media only screen and (max-height: 768px) and (min-width: 1216px) { 
        
        .board-content {
            justify-content: flex-start;

        }
        .board-content .text-board {
            padding-bottom: $boardContentBottomSpacer; 
        }
        .board-figure-1 {
            grid-row: 1 / 4;
        } 
        .board-figure-2 {
            grid-row: 1 / 3;
        } 
        .board-figure-3 {
            grid-row: 3 / 4;
        } 
        .board-content {
            grid-row: 1 / 5;
        }
    }
}
